  
import React, { useState, useEffect } from 'react';
import { Spinner, Card, Container, CardHeader, Row, Button, Input, CardBody, InputGroup, InputGroupAddon, InputGroupText, Table, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getSupportData } from "../../../api/users";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';
import dayjs from 'dayjs';

export default function TechnicalSupport() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const res = await getSupportData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
      setDataSearch(res.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = data.filter( (item) => {
        const itemData = item.email
          ? item.email.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setDataSearch(newData);
    } 
    else {
      setSearch(text);
      setDataSearch(data);
    }
  }

  const openInfoData = (d) => {
    setModalData(d);
    setModal(!modal);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Soporte técnico </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizonal'>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={search}
                      style={{paddingLeft: '5px'}}
                      placeholder={"Buscar"}
                      onChange={(val) =>  handleSearch(val.target.value)}
                      />
                  </InputGroup>
                  <br />
                </div>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Nombre </th>
                      <th scope="col"> Email </th>
                      <th scope="col"> Asunto </th>
                      <th scope="col"> Fecha </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: getColor()}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Ha ocurrido un error, por favor intente nuevamente otra vez.</label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getData()}> Cargar </Button>
                        </td>
                      </tr>:
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No hay información registrada </td>
                      </tr>
                      :
                      dataSearch.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            {d.user_name}
                          </td>
                          <td>
                            {d.user_email}
                          </td>
                          <td>
                            {d.subject}
                          </td>
                          <td>
                            {dayjs(d.date).add(1, 'day').format('MM/DD/YYYY')}
                          </td>
                          <td className="text-right">
                            <Button 
                              size='sm'
                              style={{backgroundColor: getColor(), color: "#ffffff"}} 
                              onClick={() => openInfoData(d)}
                            > Ver </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal()}> Ticket </ModalHeader>
        <ModalBody>
          <ul style={{
            padding: 0,
            listStyle: "none",
            lineHeight: "2"
          }}>
            <li>
              <b> Nombre: </b> {modalData.user_name}
            </li>
            <li>
              <b> Email: </b> {modalData.user_email}
            </li>
            <li>
              <b> Fecha: </b> {dayjs(modalData.date).add(1, 'day').format('MM/DD/YYYY')}
            </li>
            <li>
              <b> Asunto: </b> {modalData.subject}
            </li>
            <li>
              <b> Mensaje: </b> {modalData.messages}
            </li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(!modal)}> Cerrar </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
