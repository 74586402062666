/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {Card, Container, Row, Col, CardBody} from "reactstrap";
import Header from "components/Headers/Header";
import { getInfoData } from '../../api/users';

export default function Welcome() {
  const [agency, setAgency] = useState({});
  const [info, setInfo] = useState({});

  useEffect(() => {
    const customizeData = JSON.parse(localStorage.getItem("agency"));

    setAgency({
      name: customizeData.name,
      logo: customizeData.logo,
      color: customizeData.color,
      slogan: customizeData.slogan,
      id: customizeData.id
    });

    getInfo();
  }, []);

  const getInfo = async () => {
    const res = await getInfoData(agency.id);
    setInfo(res.data);
  }

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <div style={{
                textAlign: 'center'
              }}>
                <img alt="...." src={agency.logo} 
                style={{
                  marginTop: '30px',
                  width: '200px',
                  opacity: '0.9'
                }}/>
                <br /> 
                <br />
                <p style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  color: agency.color
                }}> {agency.slogan} </p>
                <br />
                <br />
              </div>
              
              <CardBody>
                <Row style={{
                  textAlign: 'center'
                }}>
                  <Col md="4">
                    <label style={{fontSize: "20px", fontWeight: "bold"}}>Telefono</label>
                    <p style={{fontWeight: "200"}}>{info.phone}</p>
                  </Col>
                  
                  <Col md="4">
                    <label style={{fontSize: "20px", fontWeight: "bold"}}>Redes sociales</label>
                    <ul style={{
                      listStyle: 'none',
                      padding: '0px',
                      display: 'flex',
                      justifyContent: 'space-around'
                    }}>
                      {
                        ( info.facebook !== null) ? 
                        <li style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}>
                          <i className="fab fa-facebook-square" style={{color: '#3b5998'}}></i>
                          <a style={{marginLeft: "5px"}} href={info.facebook} target="_blank" rel="noopener noreferrer"> 
                            {info.facebook}
                          </a>
                        </li> : <></>
                      }

                      {
                        (info.instagram !== null) ? 
                        <li style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}>
                          <i className="fab fa-instagram-square" style={{color: '#3f729b'}}></i>
                          <a style={{marginLeft: "5px"}} href={info.instagram} target="_blank" rel="noopener noreferrer"> 
                            {info.instagram}
                          </a>
                        </li> : <></>
                      }
                      
                      {
                        (info.twitter !== null) ? 
                        <li style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}>
                          <i className="fab fa-twitter-square" style={{color: '#00acee'}}></i>
                          <a style={{marginLeft: "5px"}} href={info.twitter} target="_blank" rel="noopener noreferrer"> 
                            {info.twitter}
                          </a>
                        </li> : <></>
                      }
                    </ul>
                  </Col>

                  <Col md="4">
                    <label style={{fontSize: "20px", fontWeight: "bold"}}>Correo electronico</label>
                    <p>
                      <a href={`mailto:${info.email}`}>{info.email}</a>
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
