import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "assets/css/global.css";
import App from './App';

Sentry.init({
  dsn: "https://510d0f36137495c31be917f824b593db@o4505989982912512.ingest.sentry.io/4505989989072896",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "portal.vboxnow.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
});

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
