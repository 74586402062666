/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { editTransportData, getTransportByIdData, deleteTransportData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';

export default function NewTransport() {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({
    page: true,
    btn: false
  });
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [descrption, setDescription] = useState('');
  const [contact, setContact] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState('0');
  const [modal, setModal] = useState(false);
  
  useEffect(() => {
    getTransportById();
  }, []);

  const getTransportById = async () => {
    const res = await getTransportByIdData(id);

    if(res.success === true) {
      setName(res.data.name);
      setDescription(res.data.description);
      setContact(res.data.info);
      setPhone(res.data.phone);
      setEmail(res.data.email);
      setActive(res.data.active);
      setError(false);
    }
    else {
      setError(true);
    }

    setLoading(false);
  }

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const addNewData = async () => {
    setLoading(true);
    
    if(String(name).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el nombre de la empresa de transporte");
    }
    else if(String(contact).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el nombre del contacto");
    }
    else if (String(phone).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el teléfono del contacto");
    }
    else {
      const jsn = {
        "id": id,
        "name": name,
        "description": descrption,
        "contact_info": contact,
        "phone": phone,
        "email": email,
        "active": active,
      }

      const res = await editTransportData(jsn);

      if(res.success === true) {
        notifyAlert("success", "Éxito", "El transportista se ha modificado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al modificar el transportista, por favor intente nuevamente");
      }
    }

    setLoading(false);
  }

  const deleteTransport = async () =>  {
    const res = await deleteTransportData(id);
  
    if(res.success === true) {
      window.location.href = '/admin/transports'
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al eliminar al transportista");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Transporte </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    (loading.page) ? <div className='text-center'>
                      <Spinner style={{
                        color: getColor()
                      }} />
                    </div> : <>
                      {
                        (error === true) ? <>
                          <Row>
                            <Col lg="12" className='text-center'>
                              <h3> Ha ocurrido un error al cargar los datos, por favor intente nuevamente </h3>
                            </Col>
                          </Row>
                        </> : <>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label"> Nombre </label>
                                <Input
                                  value={name}
                                  className="form-control"
                                  onChange={val => setName(val.target.value)}/>
                              </FormGroup>
                            </Col>

                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label"> Descripcion </label>
                                <Input
                                  type='textarea'
                                  value={descrption}
                                  className="form-control"
                                  onChange={val => setDescription(val.target.value)}/>
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label"> Contacto </label>
                                <Input
                                  value={contact}
                                  className="form-control"
                                  onChange={val => setContact(val.target.value)}/>
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label"> Telefono </label>
                                <Input
                                  value={phone}
                                  className="form-control"
                                  onChange={val => setPhone(val.target.value)}/>
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label"> Email </label>
                                <Input
                                  value={email}
                                  className="form-control"
                                  onChange={val => setEmail(val.target.value)}/>
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label"> Activo </label>
                                <select
                                  style={{
                                    appearance: 'auto'
                                  }}
                                  className="form-control"
                                  value={active}
                                  onChange={val => setActive(val.target.value)}
                                >
                                  <option value="1"> Si </option>
                                  <option value="0"> No </option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12" >
                              <br />
                              <div className="float-right">
                                {
                                  (loading.btn) ? <Spinner style={{color: getColor()}}/> :
                                  <div>
                                    <Button color="gray" to="/admin/transports" tag={Link}> Regresar </Button>
                                    <Button color='danger' onClick={() => setModal(!modal)}> Eliminar </Button>
                                    <Button
                                      style={{
                                        backgroundColor: getColor(),
                                        color: '#fff',
                                        border: 0
                                      }}
                                      onClick={() => addNewData()}
                                      > Guardar
                                    </Button>
                                  </div>
                                } 
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
        <ModalBody>
          ¿Está seguro que desea eliminar el transportista?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal()}> Cancelar </Button>
          <Button color="danger" onClick={() => deleteTransport()}> Eliminar </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
