/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Spinner, Container, Row, Col } from "reactstrap";
import { getAllDestinationData } from "../../api/open";
import { getColor, getAgencyData } from 'utils/token';
import OpenHeader from 'components/Headers/OpenHeader';

export default function Destination() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  
  useEffect(() => {
    getAllDestination();
  }, []);

  const getAllDestination = async () => {
    setLoading(true);
    const res = await getAllDestinationData();
    if(res.success === true) {
      setError(false);
      setData(res.data);
    }
    else {
      setError(true);
      setData([]);
    }
    setLoading(false );
  }

  return (
    <>
      <Container fluid>
        <OpenHeader />
        <Row>
          <div className="col">
            <div>
              <Row>
                <div className="col text-center">
                  <h3 style={{
                    fontSize: '2.5rem',
                  }}> Destinos {getAgencyData("name")} </h3>
                </div>
              </Row>
              
              <div className="pl-lg-4">
                <Row>
                  <Col md="12">
                    {
                      (error) ? (
                        <div className="errMsgServices text-center">
                          <label> Ha ocurrido un error al obtener los destinos. </label>
                        </div>
                      ) : 
                      (loading) ? (
                        <Spinner style={{ color: getColor() }} />
                      ) : 
                      <div>
                        {
                          data.map((dest, i) => (
                            <div key={i}>
                              <h3 style={{fontSize: '2.0rem'}}> {dest.state} </h3>
                              <hr style={{ marginTop: "0px" }}/>

                              <ul style={{
                                display: 'flex',
                                padding: '0',
                                listStyle: 'none',
                                flexWrap: 'wrap'
                              }}>
                                {
                                  JSON.parse(dest.city).map((c, j) => (
                                    <li style={{
                                      marginRight: '5px',
                                    }} key={j}>
                                      {c.city}{j !== JSON.parse(dest.city).length - 1 ? ',' : ''}
                                    </li>
                                  ))
                                }
                              </ul>
                            </div>
                          ))
                        }
                      </div>
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}
