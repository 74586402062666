/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getAllSetttingData, setAllSettingData, setCompanyPicture } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';
import Compressor from 'compressorjs';

export default function Settings() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({page: false, btn: false, picture: false, whitePicture: false, blackPicture: false });
  const arrState = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ];

  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [state, setState] = useState('');
  const [slogan, setSlogan] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [address, setAddress] = useState("");
  const [pickup, setPickup] = useState("");
  const [profit, setProfit] = useState("");
  const [profitAir, setProfitAir] = useState("");
  const [color, setColor] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");  
  const [picture, setPicture] = useState(null);
  const [pictureWhite, setPictureWhite] = useState(null);
  const [pictureBlack, setPictureBlack] = useState(null);
  const [file, setFile] = useState(null);
  const [fileWhite, setFileWhite] = useState(null);
  const [fileBlack, setFileBlack] = useState(null);

  useEffect(() => {
    getAllSettting();
  }, []);

  const getAllSettting = async () => {
    setLoading({...loading, page: true });

    const res = await getAllSetttingData();

    if(res.success === true) {
      setName(res.data.name);
      setShortName(res.data.short_name);
      setState(res.data.state_code);
      setSlogan(res.data.slogan);
      setCity(res.data.city);
      setZipCode(res.data.zip_code);
      setPhone(res.data.phone);
      setSupportEmail(res.data.support_email);
      setAddress(res.data.address);
      setPickup(res.data.ups_pickup_cost);
      setProfit(res.data.profit);
      setProfitAir(res.data.profit_air);
      setColor(res.data.primary_color);
      setFacebook(res.data.facebook);
      setTwitter(res.data.twitter);
      setInstagram(res.data.instagram);
      setPicture(res.data.logo_url);
      setPictureWhite(res.data.alt_logo_url);
      setPictureBlack(res.data.logo_black);

    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener la información");
    }

    setLoading({...loading, page: false });
  }

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const saveCompany = async () => {
    setLoading({...loading, btn: true });

    if(name === "" || shortName === "" || state === "" || slogan === "" || city === "" || phone === "" || supportEmail === "" || address === "" || profit === "" || color === "" || facebook === "" || twitter === "" || instagram === "" || zipCode === "") {
      notifyAlert("danger", "Error", "Debe completar todos los campos");
    }
    //regex to validate email
    else if(!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(supportEmail)) {
      notifyAlert("danger", "Error", "El correo electronico de soporte tecnico no es valido");
    }
    else if(Number(profit) < 1 || Number(profit) > 99) {
      notifyAlert("danger", "Error", "El porcentaje de ganancia debe ser mayor a 0 y menor a 100");
    }
    else {
      let stateName = "";
      arrState.map((item) => {
        if(item.value === state) {
          stateName = item.label;
        }
      });

      const data = {
        "name": name,
        "short_name": shortName,
        "state_code": state,
        "state_name": stateName,
        "slogan": slogan,
        "city": city,
        "phone": phone,
        "support_email": supportEmail,
        "address": address,
        "profit": profit,
        "profit_air": profitAir,
        "primary_color": color,
        "facebook": facebook,
        "twitter": twitter,
        "instagram": instagram,
        "zip_code": zipCode
      }
  
      const res = await setAllSettingData(data);
  
      if(res.success === true) {
        notifyAlert("success", "Correcto", "Se ha actualizado la información correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al actualizar la información");
      }
    }

    setLoading({...loading, btn: false });
  }

  const renderImageToSend = async (f) => {
    return new Compressor(f, {quality: 0.6});
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setPicture(e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
      setFile(event.target.files[0]);
    }
  }

  const onImageChangeWhite = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setPictureWhite(e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
      setFileWhite(event.target.files[0]);
    }
  }

  const onImageChangeBlack = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setPictureBlack(e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
      setFileBlack(event.target.files[0]);
    }
  }

  const uploadPicture = async () => {
    setLoading({...loading, picture: true});
    let fileCompress = null;
    
    if(file && Object.keys(file).length === 0 && file.constructor === Object) {
      notifyAlert("danger", "Mensaje", "No se ha seleccionado ninguna imagen");
    }
    else {
      fileCompress = await renderImageToSend(file);

      const res = await setCompanyPicture(fileCompress, 'logo');

      if(res.success === true) { 
        window.location.reload();
      }
      else {
        notifyAlert("danger", "", res.error.message);
      }
    }
    setLoading({...loading, picture: false});
  }

  const uploadWhitePicture = async () => {
    setLoading({...loading, whitePicture: true});
    let fileCompress = null;
    
    if(fileWhite && Object.keys(fileWhite).length === 0 && fileWhite.constructor === Object) {
      notifyAlert("danger", "Mensaje", "No se ha seleccionado ninguna imagen");
    }
    else {
      fileCompress = await renderImageToSend(fileWhite);
      
      const res = await setCompanyPicture(fileCompress, 'logo_white');

      if(res.success === true) { 
        window.location.reload();
      }
      else {
        notifyAlert("danger", "", res.error.message);
      }
    }
    setLoading({...loading, whitePicture: false});
  }

  const uploadBlackPicture = async () => {
    setLoading({...loading, blackPicture: true});
    let fileCompress = null;
    
    if(fileBlack && Object.keys(fileBlack).length === 0 && fileBlack.constructor === Object) {
      notifyAlert("danger", "Mensaje", "No se ha seleccionado ninguna imagen");
    }
    else {
      fileCompress = await renderImageToSend(fileBlack);
      
      const res = await setCompanyPicture(fileCompress, 'logo_black');

      if(res.success === true) { 
        window.location.reload();
      }
      else {
        notifyAlert("danger", "", res.error.message);
      }
    }
    setLoading({...loading, blackPicture: false});
  }

  

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Configuraciones </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    (loading.page === true) ? <div className='text-center'>
                      <Spinner 
                        style={{
                          color: getColor()
                        }} />
                    </div> : <>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Nombre </label>
                            <Input
                              value={name}
                              className="form-control"
                              onChange={val => setName(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Nombre Corto</label>
                            <Input
                              value={shortName}
                              className="form-control"
                              onChange={val => setShortName(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> Slogan </label>
                            <Input
                              value={slogan}
                              className="form-control"
                              onChange={val => setSlogan(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Estado </label>
                            <select 
                              value={state}
                              className='form-control'
                              onChange={(val) => setState(val.target.value)}>
                              <option value=""> Seleccionar </option>
                              {
                                arrState.map((item, key) => (
                                  <option key={key} value={item.value}> {item.label} </option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Ciudad </label>
                            <Input
                              value={city}
                              className="form-control"
                              onChange={val => setCity(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Codigo Postal </label>
                            <Input
                              value={zipCode}
                              className="form-control"
                              onChange={val => setZipCode(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Telefono </label>
                            <Input
                              value={phone}
                              className="form-control"
                              onChange={val => setPhone(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Correo Electronico de Soporte Tecnico </label>
                            <Input
                              value={supportEmail}
                              className="form-control"
                              onChange={val => setSupportEmail(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> Direccion </label>
                            <Input
                              type="textarea"
                              value={address}
                              className="form-control"
                              onChange={val => setAddress(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Costo Pickup UPS </label>
                            <Input
                              disabled={true}
                              defaultValue={pickup}
                              className="form-control" />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Ganancia Maritima </label>
                            <Input
                              type='number'
                              min={1}
                              max={99}
                              pattern='[0-9]+([,\.][0-9]+)?'
                              value={profit}
                              className="form-control"
                              onChange={val => setProfit(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Ganancia Aerea </label>
                            <Input
                              type='number'
                              min={1}
                              max={99}
                              pattern='[0-9]+([,\.][0-9]+)?'
                              value={profitAir}
                              className="form-control"
                              onChange={val => setProfitAir(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Color </label>
                            <div style={{
                              display: 'flex',
                            }}>
                              <Input
                                type='text'
                                value={color}
                                style={{
                                  width: '70%',
                                }}
                                className="form-control"
                                onChange={val => setColor(val.target.value)}/>
                              <Input
                                style={{
                                  width: '29%',
                                }}
                                type='color'
                                value={color}
                                className="form-control"
                                onChange={val => setColor(val.target.value)}/>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Facebook </label>
                            <Input
                              value={facebook}
                              className="form-control"
                              onChange={val => setFacebook(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label"> Instagram </label>
                            <Input
                              value={instagram}
                              className="form-control"
                              onChange={val => setInstagram(val.target.value)}/>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" >
                          <br />
                          <div className="float-right">
                            {
                              (loading.btn) ? <Spinner style={{color: getColor()}}/> :
                              <div>
                                <Button
                                  style={{
                                    backgroundColor: getColor(),
                                    color: '#fff',
                                    border: 0
                                  }}
                                  onClick={() => saveCompany()}
                                  > Guardar
                                </Button>
                              </div>
                            } 
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <label className="form-control-label"> Logo principal </label>
                          <div 
                            id="dvImage"
                            style={{
                              width: '100%',
                              height: '150px',
                              marginBottom: '10px',
                              borderRadius: '.25rem',
                              border: '1px solid #ced4da'
                            }}>
                              <img 
                                style={{
                                  width: '100%',
                                  height: '148px',
                                  objectFit: 'scale-down',
                                }}
                                alt="Cargar"
                                src={picture} />
                          </div>

                          <div className="float-right">
                            <label className="custom-file-upload">
                              <input 
                                type="file"
                                accept="image/*"
                                onChange={onImageChange}/>
                              Cargar
                            </label>

                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => uploadPicture()}
                              > Guardar
                            </Button>
                          </div>

                        </Col>

                        <Col lg="6">
                          <label className="form-control-label"> Logo blanco </label>
                          <div 
                            id="dvImage"
                            style={{
                              width: '100%',
                              height: '150px',
                              marginBottom: '10px',
                              borderRadius: '.25rem',
                              border: '1px solid #ced4da',
                              backgroundColor: "#202124"
                            }}>
                              <img 
                                style={{
                                  width: '100%',
                                  height: '148px',
                                  objectFit: 'scale-down',
                                }}
                                alt="Cargar"
                                src={pictureWhite} />
                          </div>

                          <div className="float-right">
                            <label className="custom-file-upload">
                              <input 
                                type="file"
                                accept="image/*"
                                onChange={onImageChangeWhite}/>
                              Cargar
                            </label>

                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => uploadWhitePicture()}
                              > Guardar
                            </Button>
                          </div>
                        </Col>

                        <Col lg="6">
                          <label className="form-control-label"> Logo negro </label>
                          <div 
                            id="dvImage"
                            style={{
                              width: '100%',
                              height: '150px',
                              marginBottom: '10px',
                              borderRadius: '.25rem',
                              border: '1px solid #ced4da'
                            }}>
                              <img 
                                style={{
                                  width: '100%',
                                  height: '148px',
                                  objectFit: 'scale-down',
                                }}
                                alt="Cargar"
                                src={pictureBlack} />
                          </div>

                          <div className="float-right">
                            <label className="custom-file-upload">
                              <input 
                                type="file"
                                accept="image/*"
                                onChange={onImageChangeBlack}/>
                              Cargar
                            </label>

                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => uploadBlackPicture()}
                              > Guardar
                            </Button>
                          </div>

                        </Col>
                      </Row>
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
