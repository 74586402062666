/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import { makeRateData, 
  getAllStateData,
  getCityByStateData } from "../../../api/users";
import { getColor, getAgencyData } from 'utils/token';

export default function AdminCalculator() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({btn: false, state: false, city: false});
  const [error, setError] = useState({data: false, state: false, city: false});
  const [arrState, setArrState] = useState([]);
  const [arrCity, setArrCity] = useState([]);
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [labelType, setLabelType] = useState('');
  const [consignationType, setConsignationType] = useState('');
  const [weight, setWeight] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllStateByAgency();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getAllStateByAgency = async () => {
    setLoading({ ...loading, state: true });
    const res = await getAllStateData();
    if(res.success === true) {
      setError({ ...error, state: false });
      setArrState(res.data);
    }
    else {
      setError({ ...error, state: true });
      setArrState([]);
    }
    setLoading({ ...loading, state: false });
  }

  const getCityByState = async (val) => {
    setLoading({ ...loading, city: true });

    setState(val);
    const res = await getCityByStateData(val);

    if(res.success === true) {
      setError({ ...error, city: false });
      setArrCity(res.data);
    }
    else {
      setError({ ...error, city: true });
      setArrCity([]);
    }

    setLoading({ ...loading, city: false});
  }

  const validInput = (inp) => {
    switch (inp) {
      case "weight":
        if (parseFloat(weight) > 150) {
          setWeight("150");
        }
        break;

      case "length":
        if (parseFloat(length) > 108) {
          setLength("108");
        }
        break;

      case "width":
        if (parseFloat(width) > 108) {
          setWidth("108");
        }
        break;

      case "height":
        if (parseFloat(height) > 108) {
          setHeight("108");
        }
        break;
      default:
        return false;
    }
  }

  const validData = () => {
    return (
      zipCode !== "" &&
      city !== "" &&
      consignationType !== "" &&
      labelType !== "" &&
      weight !== "" &&
      height !== "" &&
      length !== "" &&
      width !== "" &&
      parseFloat(weight) >= 1 &&
      parseFloat(height) >= 1 &&
      parseFloat(length) >= 1 &&
      parseFloat(width) >= 1
    );
  }

  const weightConverter = (val) => {
    return (parseFloat(val) / 2.2046).toFixed(2);
  }

  const makeRate = async () => {
    var productsArr = [];
    const total_pkg_dim = parseFloat(length) + 2 * ( parseFloat(width) + parseFloat(height) );

    setLoading({ ...loading, btn: true });

    if(total_pkg_dim > 165) {
      notifyAlert("danger", "Mensaje", "Las dimensiones del paquete no pueden ser mayores a 165 pulgadas");
    }
    else {
      const jsn = {
        'zipCode': zipCode,
        'state': state,
        'city': city,
        'weight': weight,
        'length': length,
        'width': width,
        'height': height
      };
      
      const res = await makeRateData(jsn);
  
      if(res.success === true) {
        productsArr = res.data.products;
        
        if(labelType === "all") {
          const productsArrFinal = productsArr.map((item) => {
            var total = (consignationType === "1") ? (Number(item.payment) + Number(res.data.ups_pickup)) : Number(item.payment);
            return { ...item, "total": Number(total).toFixed(2)}
          });
  
          setData(productsArrFinal);
        }
        else {
          const productsArrFinal = productsArr.map((item) => {
            var total = (consignationType === "1") ? (Number(item.payment) + Number(res.data.ups_pickup)) : Number(item.payment);
            return { ...item, "total": Number(total).toFixed(2)}
          });
  
          console.log("productsArrFinal", productsArrFinal);
  
          const products = productsArrFinal.filter((item) => {
            if(String(item.type).toLowerCase() === labelType) {
              return item;
            }
          });
  
          setData(products);
        }
        setError({ ...error, data: false });
      }
      else {
        setError({ ...error, data: true });
        notifyAlert("danger", "Mensaje", "Ha ocurrido un error al calcular su paquete");
      }

    }

    setLoading({ ...loading, btn: false });
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Calculadora de envio </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="4">
                      <div style={{
                        border: '1px solid #d3d3d4',
                        borderRadius: '10px',
                        padding: '25px 25px 15px',
                        marginBottom: '20px'
                      }}>
                        <label style={{
                          fontSize: '1.28rem',
                          fontWeight: '600',
                          color: getColor(),
                        }}> Origen </label>
                         <img alt="" 
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "10px"
                          }}
                          src={require('./../../../assets/img/usa.png').default} className="flag-img" />
                        <FormGroup>
                          <label className="form-control-label">Código Postal (USA)</label>
                          <Input
                            placeholder="Codigo postal"
                            type="text"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                          />
                        </FormGroup>
                      </div>
                    </Col>

                    <Col md="8">
                      <div style={{
                        border: '1px solid #d3d3d4',
                        borderRadius: '10px',
                        padding: '25px 25px 15px',
                        marginBottom: '20px'
                      }}>
                         <label style={{
                          fontSize: '1.28rem',
                          fontWeight: '600',
                          color: getColor(),
                        }}> Destino </label>
                         <img alt="" 
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "10px"
                          }}
                          src={require('./../../../assets/img/ve.png').default} className="flag-img" />
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-control-label">Seleccione Estado</label>
                              <select
                                className="form-control"
                                  value={state}
                                  onChange={(e) => getCityByState(e.target.value)}
                                >
                                <option value=""> Seleccionar </option>
                                {
                                  (loading.state) ? <>
                                    <Spinner size='sm' style={{ color: getColor() }}/>
                                  </> : <>
                                    {
                                      arrState.map((item, key) => (
                                        <option key={key} value={item.id}> {item.name} </option>
                                      ))
                                    }
                                  </>
                                }
                              </select>
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <label className="form-control-label">Seleccione Ciudad</label>
                              <select
                                value={city}
                                className="form-control"
                                onChange={(e) => setCity(e.target.value)}>
                                <option value=""> Seleccionar </option>
                                {
                                  (loading.city) ? <>
                                    <Spinner size='sm' style={{ color: getColor() }}/>
                                  </> : <>
                                    {
                                      arrCity.map((item, key) => (
                                        <option key={key} value={item.id}> {item.name} </option>
                                      ))
                                    }
                                  </>
                                }
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col md="12">
                      <div style={{
                        border: '1px solid #d3d3d4',
                        borderRadius: '10px',
                        padding: '25px 25px 15px',
                        marginBottom: '20px'
                      }}>
                        <label style={{
                          fontSize: '1.28rem',
                          fontWeight: '600',
                          color: getColor(),
                        }}> Tipo de envío </label>

                        <Row>
                          <Col sm="12" md="6">
                            <FormGroup>
                              <label className="form-control-label"> Selecciona el tipo de etiqueta </label>
                              <select
                                id="label-select"
                                className="form-control"
                                value={labelType}
                                onChange={(e) => setLabelType(e.target.value)}
                                >
                                  <option value=""> Seleccionar </option>
                                  <option value="all"> Todos </option>
                                  <option value="air">Aéreo</option>
                                  <option value="ocean">Marítimo</option>
                                </select>
                            </FormGroup>
                          </Col>

                          <Col sm="12" md="6">
                            <FormGroup>
                              <label className="form-control-label"> Seleccionar el tipo de consignación (USA) </label>
                              <select
                                  id="label-select"
                                  className="form-control"
                                  value={consignationType}
                                  onChange={(e) => setConsignationType(e.target.value)}
                                >
                                  <option value=""> Seleccionar </option>
                                  <option value="1"> Recogida por UPS </option>
                                  <option value="0"> Entregar en oficina de UPS </option>
                                </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col md="12">
                      <div style={{
                        border: '1px solid #d3d3d4',
                        borderRadius: '10px',
                        padding: '25px 25px 15px',
                        marginBottom: '20px'
                      }}>
                        <label style={{
                          fontSize: '1.28rem',
                          fontWeight: '600',
                          color: getColor(),
                        }}> Peso y dimensiones </label>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label">Peso (Libras)</label>
                              <Input
                                type="number"
                                min="1"
                                max="150"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                                onKeyPress={validInput("weight")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label"> Largo (Pulgadas) </label>
                              <Input
                                type="number"
                                min="1"
                                max="150"
                                value={length}
                                onChange={(e) => setLength(e.target.value)}
                                onKeyPress={validInput("length")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label"> Ancho (Pulgadas) </label>
                              <Input
                                type="number"
                                min="1"
                                max="150"
                                value={width}
                                onChange={(e) => setWidth(e.target.value)}
                                onKeyPress={validInput("width")}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label"> Alto (Pulgadas) </label>
                              <Input
                                type="number"
                                min="1"
                                max="150"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                                onKeyPress={validInput("height")}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12" className="text-center">
                      <div>
                        {loading.btn ? (
                          <Spinner
                            style={{ color: getColor(), marginBottom: "24px" }}
                          />
                        ) : (
                          <Button
                            style={{
                              backgroundColor: getColor(),
                              color: "#fff",
                              fontWeight: "600",
                              fontSize: "1.28rem",
                              padding: "10px 30px",
                              width: "150px",
                              cursor: "pointer"
                            }}
                            disabled={!validData()}
                            className={validData() ? "btnRate" : "btnDisable"}
                            onClick={() => makeRate()}
                          > Calcular 
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col md="12">
                      {
                        (error.data) ? (
                          <div className="errMsgServices">
                            <label> Ha ocurrido un error al obtener el calculo </label>
                          </div>
                        ) :
                        (data.length > 0) ?  
                          <>
                            <div 
                              style={{
                                fontWeight: "bold",
                                color: getColor(),
                                textAlign: "center",
                                marginTop: "20px",
                              }}>
                              <h2 style={{
                                fontSize: "2.3rem"
                              }}> Nuestros servicios {getAgencyData('name')} </h2>
                              <hr />
                            </div>
                            {data.map((pkg, i) => (
                              <div key={i}>
                                <Row>
                                  <Col md="4" className="align-self-center text-center">
                                    <img 
                                      alt="..." 
                                      style={{
                                        width: "50%",
                                        objectFit: "scale-down"
                                      }}
                                      src={pkg.picture} />

                                    <div className="dvTitle">
                                      <span> {pkg.name} </span>
                                    </div>
                                  </Col>

                                  <Col md="4" className="align-self-center">
                                    <ul style={{
                                      padding: "0",
                                      lineHeight: "3",
                                      fontSize: "15px",
                                      listStyle: "none"
                                    }}>
                                      <li>
                                        <b> Dimensiones: </b>
                                        <span>
                                          {" "}
                                          {length}"x{width}"x{height}"{" "}
                                        </span>
                                      </li>
                                     
                                      <li>
                                        <b> Peso: </b>
                                        <span>
                                          {" "}
                                          {weight}lbs ({weightConverter(weight)}Kg){" "}
                                        </span>
                                      </li>
                                      <li>
                                        <b> Servicio: </b>
                                        {pkg.type === "OCEAN" ? (
                                          <span>
                                            Marítimo
                                            <i style={{marginLeft: "7px", color: getColor()}} className='fas fa-ship' />
                                          </span>
                                        ) : (
                                          <span>
                                            Aéreo
                                            <i style={{marginLeft: "7px", color: getColor()}} className='fas fa-plane' />
                                          </span>
                                        )}
                                      </li>
                                       <li>
                                        <b> Tipo de pickup: </b>
                                        {
                                        (consignationType === "1") ? 
                                          <span> Recogida por UPS </span> :
                                          <span> Entregar en oficina UPS</span>
                                        }
                                      </li>
                                    </ul>
                                  </Col>

                                  <Col md="4" className="align-self-center">
                                    <div>
                                    <ul style={{
                                      padding: "0",
                                      lineHeight: "3",
                                      fontSize: "15px",
                                      listStyle: "none"
                                    }}>
                                      <li style={{
                                        textTransform: "uppercase",
                                        fontSize: "18px"
                                      }}>
                                        <b>Precio del envío: </b>
                                        <span style={{color: getColor(), fontWeight: "bold"}}> 
                                          ${pkg.total}
                                        </span>
                                      </li>
                                    </ul>
                                    </div>
                                    
                                    <Button 
                                      style={{
                                        backgroundColor: getColor(),
                                        color: "#fff",
                                        fontWeight: "600",
                                        fontSize: "1.28rem",
                                        padding: "10px 30px",
                                        width: "200px",
                                        cursor: "pointer",
                                        textAlign: "center",
                                        borderRadius: "5px",
                                      }}
                                      to={`/admin/services/${pkg.sku}`} 
                                      tag={Link}
                                    > Enviar 
                                      <i
                                        style={{ marginLeft: "7px" }}
                                        className='fas fa-shopping-cart' />
                                    </Button>
                                  </Col>
                                </Row>

                                {data.length - 1 === i ? (
                                  ""
                                ) : (
                                  <hr style={{ width: "80%" }} />
                                )}
                              </div>
                            ))}
                          </>
                        : <></>
                      }
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}
