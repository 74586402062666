/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Table, Row, Button, Col, FormGroup, Input, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getColor } from "utils/token";
import { getAllStateData, 
  getCityByStateData, 
  createReceiveAddressData, 
  getAllReceiveAddressData,
  updateReceiveAddressData,
  deleteReceiveAddressData } from "./../../../api/users";

export default function Address() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ load: false, table: true});
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [arrState, setArrState] = useState([]);
  const [state, setState] = useState('');
  const [arrCity, setArrCity] = useState([]);
  const [city, setCity] = useState('');
  const [data, setData] = useState([]);
  const [error, setErrror] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [arrCityModal, setArrCityModal] = useState([]);
  const [arrStateModal, setArrStateModal] = useState([]);
  const [delId, setDelId] = useState(0);
  const [titleModal, setTitleModal] = useState('');
  const [nameModal, setNameModal] = useState('');
  const [phoneModal, setPhoneModal] = useState('');
  const [emailModal, setEmailModal] = useState('');
  const [addressModal, setAddressModal] = useState('');
  const [stateModal, setStateModal] = useState('');
  const [cityModal, setCityModal] = useState('');

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    funct(); 
  },[]);

  const funct = async () => {
    getAllState();
    getAllReceiveAddress();
  }

  const getAllState = async () => {
    const res = await getAllStateData();
    setArrState(res.data);
    setArrStateModal(res.data);
  }

  const getCityByState = async (id) => {
    const res = await getCityByStateData(id);
    setArrCity(res.data);
    setState(id);
  }
  
  const getCityByStateModal = async (id) => {
    const res = await getCityByStateData(id);
    setArrCityModal(res.data);
  }

  const getAllReceiveAddress = async () => {
    const res = await getAllReceiveAddressData();

    if(res.success === true) {
      setErrror(false);
      setData(res.data);
    }
    else {
      setErrror(true);
    }

    setLoading({...loading, table: false});
  }

  const saveData = async () => {
    setLoading({...loading, save: true});

    if(String(title).trim() === "") {
      notifyAlert("default", "Mensaje", "El titulo es requerido");
    }
    else if(String(name).trim() === "") {
      notifyAlert("default", "Mensaje", "El name es requerido");
    }
    else if (String(phone).trim() === "") {
      notifyAlert("default", "Mensaje", "El telefono es requerido");
    }
    else if (String(email).trim() === "") {
      notifyAlert("default", "Mensaje", "El email es requerido");
    }
    else if (String(state).trim() === "") {
      notifyAlert("default", "Mensaje", "El estado es requerido");
    }
    else if (String(city).trim() === "") {
      notifyAlert("default", "Mensaje", "La ciudad es requerida");
    }
    else if (String(address).trim() === "") {
      notifyAlert("default", "Mensaje", "La dirección es requerida");
    }
    else {
      const jsn = {
        "title": title,
        "name": name,
        "phone": phone,
        "email": email,
        "state": state,
        "city": city,
        "address": address,
      }

      const res = await createReceiveAddressData(jsn);

      if(res.success === true) {
        notifyAlert("success", "Mensaje", "Dirección guardada correctamente");
        setTitle('');
        setName('');
        setPhone('');
        setEmail('');
        setState('');
        setCity('');
        setAddress('');
        getAllReceiveAddress();
      }
      else {
        notifyAlert("danger", "Mensaje", "Error al guardar la dirección");
      }
    }

    setLoading({...loading, save: false});
  }

  const setUpdate = async (id) => {
    setModal(!modal);
    const dataValor = data[id];

    setDelId(dataValor.id);
    getCityByStateModal(dataValor.state);
    setTitleModal(dataValor.title);
    setNameModal(dataValor.name);
    setPhoneModal(dataValor.phone);
    setEmailModal(dataValor.email);
    setStateModal(dataValor.state);
    setCityModal(dataValor.city);
    setAddressModal(dataValor.address);
  }

  const updateReceiveAddress = async () => {
    setLoading({...loading, save: true});

    if(String(titleModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El titulo es requerido");
    }
    else if(String(nameModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El name es requerido");
    }
    else if (String(phoneModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El telefono es requerido");
    }
    else if (String(emailModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El email es requerido");
    }
    else if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(String(emailModal).trim()) === false) {
      notifyAlert("default", "Mensaje", "El correo no es valido");
    }
    else if (String(stateModal).trim() === "") {
      notifyAlert("default", "Mensaje", "El estado es requerido");
    }
    else if (String(cityModal).trim() === "") {
      notifyAlert("default", "Mensaje", "La ciudad es requerida");
    }
    else if (String(addressModal).trim() === "") {
      notifyAlert("default", "Mensaje", "La dirección es requerida");
    }
    else {
      const jsn = {
        "title": titleModal,
        "name": nameModal,
        "phone": phoneModal,
        "email": emailModal,
        "state": stateModal,
        "city": cityModal,
        "address": addressModal,
      }

      const res = await updateReceiveAddressData(jsn, delId);

      if(res.success === true) {
        notifyAlert("success", "Mensaje", "Dirección guardada correctamente");
        setTitleModal('');
        setNameModal('');
        setPhoneModal('');
        setEmailModal('');
        setStateModal('');
        setCityModal('');
        setAddressModal('');
        getAllReceiveAddress();
        setModal(!modal);
      }
      else {
        notifyAlert("danger", "Mensaje", "Error al guardar la dirección");
      }
    }

    setLoading({...loading, save: false});
  }


  const deleteAddressModal = async (id) => {
    setModalDelete(!modalDelete);
    setDelId(id);
  }

  const deleteAddress = async () => {
    const res = await deleteReceiveAddressData(delId);

    if(res.success === true) {
      setModalDelete(!modalDelete);
      notifyAlert("success", "Mensaje", "Dirección eliminada correctamente");
      getAllReceiveAddress();
    }
    else {
      notifyAlert("danger", "Mensaje", "Error al eliminar la dirección");
    }
  }
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div style={{
        padding: "10px"
      }}>
        <div className='paddingHorizontal'>
          <Row>
            <Col>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Titulo </label>
                    <Input
                      placeholder="Titulo de la dirección"
                      value={title}
                      className="form-control"
                      onChange={val => setTitle(val.target.value)}/>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Nombre </label>
                    <Input
                      placeholder="Nombre de quien recibe el pedido"
                      value={name}
                      className="form-control"
                      onChange={val => setName(val.target.value)}/>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Telefono </label>
                    <Input
                      placeholder="Telefono de quien recibe el pedido"
                      value={phone}
                      className="form-control"
                      onChange={val => setPhone(val.target.value)}/>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> E-Mail </label>
                    <Input
                      placeholder="Email de la persona quien recibira el pedido"
                      value={email}
                      className="form-control"
                      onChange={val => setEmail(val.target.value)}/>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Estado </label>
                    <select 
                      className='form-control'
                      onChange={(val) => getCityByState(val.target.value)}>
                      <option value=""> Seleccionar </option>
                      {
                        arrState.map((item, key) => (
                          <option key={key} value={item.id}> {item.name} </option>
                        ))
                      }
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label"> Ciudad </label>
                    <select 
                      className='form-control'
                      onChange={(val) => setCity(val.target.value)}>
                      <option value=""> Seleccionar </option>
                      {
                        arrCity.map((item, key) => (
                          <option key={key} value={item.id}> {item.name} </option>
                        ))
                      }
                    </select>
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label"> Dirección </label>
                    <Input
                      placeholder="Escriba aqui su dirección"
                      value={address}
                      className="form-control"
                      type='textarea'
                      onChange={val => setAddress(val.target.value)}/>
                  </FormGroup>
                </Col>
              </Row>
            
              <div className="float-right" style={{
                marginBottom: "20px"
              }}>
              {
                (loading.save) ? <div className="text-center">
                  <Spinner style={{
                    color: getColor()
                  }} />
                </div> :
                <>
                  <Button
                    style={{
                      backgroundColor: getColor(),
                      color: "#fff",
                      border: 0
                    }}
                    onClick={() => saveData()}
                    > Guardar
                  </Button>
                </>
              }
              </div>

            </Col>
          </Row>

          <Row>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col"> Titulo </th>
                  <th scope="col"> Estado </th>
                  <th scope="col"> Ciudad </th>
                  <th scope="col"> Dirección </th>
                  <th scope="col" />
                </tr>
              </thead>

              <tbody>
                {
                  (loading.table) ?
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <Spinner style={{color: '#004899'}} />
                    </td>
                  </tr>:
                  (error) ? 
                  <tr>
                    <td colSpan={10} className='text-muted text-center'>
                      <label> Ha ocurrido un error, por favor intente nuevamente otra vez.</label>
                      <br />
                      <Button 
                        color="secondary"> Cargar </Button>
                    </td>
                  </tr>:
                  (data.length === 0) ? 
                  <tr>
                    <td colSpan={10} className='text-muted text-center'> No hay información registrada </td>
                  </tr>
                  :
                    data.map((item, key) => (
                      <tr key={key}>
                        <td> {item.title} </td>
                        <td> {item.state_name} </td>
                        <td> {item.city_name} </td>
                        <td> {
                          String(item.address).length < 80 ? item.address :
                           String(item.address).substring(0,80).concat("...")
                          } 
                        </td>
                        <td>
                          <Button
                            color='danger'
                            size='sm'
                            onClick={() => deleteAddressModal(item.id)}
                            > Eliminar
                          </Button>
                          <Button
                            style={{
                              backgroundColor: getColor(),
                              color: "#fff",
                              border: 0
                            }}
                            size='sm'
                            onClick={() => setUpdate(key)}
                          > Editar
                          </Button>
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </Table>
          </Row>
        </div>
      </div>

      <Modal size='lg' isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal()}> Dirección </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Titulo </label>
                <Input
                  placeholder="Titulo de la dirección"
                  value={titleModal}
                  className="form-control"
                  onChange={val => setTitleModal(val.target.value)}/>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Nombre </label>
                <Input
                  placeholder="Nombre de quien recibe el pedido"
                  value={nameModal}
                  className="form-control"
                  onChange={val => setNameModal(val.target.value)}/>
              </FormGroup>
            </Col>
            
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Telefono </label>
                <Input
                  placeholder="Telefono de quien recibe el pedido"
                  value={phoneModal}
                  className="form-control"
                  onChange={val => setPhoneModal(val.target.value)}/>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> E-Mail </label>
                <Input
                  placeholder="Email de la persona quien recibira el pedido"
                  value={emailModal}
                  className="form-control"
                  onChange={val => setEmailModal(val.target.value)}/>
              </FormGroup>
            </Col>
        
            <Col lg="6">  
              <FormGroup>
                <label className="form-control-label"> Estado </label>
                <select 
                  className='form-control'
                  value={stateModal}
                  onChange={(val) => getCityByStateModal(val.target.value)}>
                  <option value=""> Seleccionar </option>
                  {
                    arrStateModal.map((item, key) => (
                      <option key={key} value={item.id}> {item.name} </option>
                    ))
                  }
                </select>
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> Ciudad </label>
                <select 
                  value={cityModal}
                  className='form-control'
                  onChange={(val) => setCityModal(val.target.value)}>
                  <option value=""> Seleccionar </option>
                  {
                    arrCityModal.map((item, key) => (
                      <option key={key} value={item.id}> {item.name} </option>
                    ))
                  }
                </select>
              </FormGroup>
            </Col>
            
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label"> Dirección </label>
                <Input
                  placeholder="Escriba aqui su dirección"
                  value={addressModal}
                  className="form-control"
                  type='textarea'
                  onChange={val => setAddressModal(val.target.value)}/>
              </FormGroup>
            </ Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(!modal)}> Cancelar </Button>
          <Button color="primary" onClick={() => updateReceiveAddress()}> Guardar </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
        <ModalHeader toggle={() => setModalDelete()}> Mensaje </ModalHeader>
        <ModalBody>
          <label> ¿Está seguro que desea eliminar esta dirección? </label>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalDelete(!modalDelete)}> Cancelar </Button>
          <Button color="danger" onClick={() => deleteAddress()}> Eliminar </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}