import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/Headers/Header";
import { Spinner, Card, CardHeader, CardBody, Container, Row, Col,  Button } from "reactstrap";
import { getAllPackagesData } from '../../api/users';
import { getColor } from '../../utils/token';

export default function Services() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllPackages();
  }, []);

  const getAllPackages = async () => {
    setLoading(true);
    const res = await getAllPackagesData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
    }
    else {
      setError(true);
      setData([]);
    }
    setLoading(false);
  }

  const getButtonText = (type) => {
    switch(type) {
      case 'OCEAN':
        return 'Enviar Marítimo';
      case 'AIR':
        return 'Enviar Aéreo';
      case 'GROUND':
        return 'Enviar Domestico';
      default:
        return 'Enviar';
    }
  }

  const linkToRedirectURL = (code) => {
    if(String(code).toLowerCase() === 'vbgd') {
      return `/admin/domestic/${code}`;
    }
    else {
     return `/admin/services/${code}`; 
    }
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Enviar </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizonal'>
                  {
                    (loading) ? <>
                      <div style={{textAlign: "center"}}>
                        <Spinner style={{color:getColor()}} />
                      </div>
                    </> : <>
                    {
                      (error) ? <>
                        <div style={{textAlign: "center"}}>
                          <h3> Ha ocurrido un error al traer los servicios, por favor intente de nuevo mas tarde.</h3>
                        </div>
                      </> : <>
                        <Row>
                          {
                            (data.map((item, i) => (
                              <Col key={i} md="4">
                                <Card style={{marginTop: "-30px", padding: "10px"}}>
                                  <div style={{textAlign: "center"}}>
                                    <img
                                      alt="..."
                                      style={{
                                        width: '150px',
                                        height: 'auto',
                                        objectFit: 'cover'
                                      }}
                                      src={item.image}
                                    />
                                    <h2 style={{
                                      color: getColor()
                                    }}>{item.name}</h2>
                                    <p> {item.description} </p>
                                    <Button 
                                      to={linkToRedirectURL(item.code)} 
                                      tag={Link}
                                      style={{
                                        backgroundColor: getColor(),
                                        color: "#fff",
                                        width: "250px",
                                        marginBottom: "10px",
                                        borderColor: getColor()
                                      }}> {getButtonText(item.type)} </Button>
                                  </div>
                                </Card> 
                              </Col>
                            )))
                          }
                        </Row>
                      </>
                    }
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
