/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Header from "components/Headers/Header";
import Info from './components/Info';
import Security from './components/Security';
import Address from './components/Address';
import AddressUS from './components/AddressUS';

export default function Profile() {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Perfil </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizontal'>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '1') ? 'active' : ''}
                        onClick={() => toggle('1') }
                      >
                        Perfil
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '2') ? 'active' : ''}
                        onClick={() => toggle('2')}
                      >
                        Dirección
                        <img 
                          alt='ve'
                          style={{
                            width: '20px',
                            marginLeft: '5px',
                            marginTop: '-3px'
                          }}
                          src={require('./../../assets/img/usa.png').default}/>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '3') ? 'active' : ''}
                        onClick={() => toggle('3')}
                      >
                        Dirección
                        <img 
                          alt='ve'
                          style={{
                            width: '20px',
                            marginLeft: '5px',
                            marginTop: '-3px'
                          }}
                          src={require('./../../assets/img/ve.png').default}/>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '4') ? 'active' : ''}
                        onClick={() => toggle('4') }
                      >
                        Seguridad
                      </NavLink>
                    </NavItem>
                  </Nav>
                  
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Info />
                    </TabPane>
                    <TabPane tabId="2">
                      <AddressUS />
                    </TabPane>
                    <TabPane tabId="3">
                      <Address />
                    </TabPane>
                    <TabPane tabId="4">
                      <Security />
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}