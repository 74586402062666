import Welcome from 'views/Welcome/Welcome';
import Agency from './views/Agency/Agency';
import Services from 'views/Services/Services';
import ConfigServices from 'views/Services/ConfigServices';
import Tracking from './views/Tracking/Tracking';
import Calculator from 'views/Calculator/Calculator';
import Support from 'views/Support/Support';
import Profile from './views/Profile/Profile';
import Products from './views/Admin/Products/Products';
import NewProduct from './views/Admin/Products/NewProduct';
import EditProduct from './views/Admin/Products/EditProduct';
import Clients from './views/Admin/Clients/Clients';
import Users from './views/Admin/Users/Users';
import NewUsers from './views/Admin/Users/NewUsers';
import EditUsers from './views/Admin/Users/EditUsers';
import Purchase from 'views/Purchase/Purchase';
import PickupCreation from 'views/Purchase/PickupCreation';
import ViewTracking from 'views/Tracking/ViewTracking';
import Coupon from 'views/Admin/Coupon/Coupon';
import NewCoupon from 'views/Admin/Coupon/NewCoupon';
import EditCoupon from 'views/Admin/Coupon/EditCoupon';
import State from 'views/Admin/Address/State';
import NewState from 'views/Admin/Address/NewState';
import EditState from 'views/Admin/Address/EditState';
import City from 'views/Admin/Address/City';
import NewCity from 'views/Admin/Address/NewCity';
import EditCity from 'views/Admin/Address/EditCity';
import Orders from 'views/Admin/Orders/Orders';
import ViewOrder from 'views/Admin/Orders/ViewOrder';
import SearchOrder from 'views/Admin/Orders/SearchOrder';
import ProcessOrder from 'views/Admin/Orders/ProcessOrder';
import FindOrder from 'views/Admin/Orders/FindOrder';
import SearchOrderView from 'views/Admin/Orders/SearchOrderView';
import Lots from 'views/Admin/Lots/Lots';
import Transport from 'views/Admin/Transport/Transport';
import NewTransport from 'views/Admin/Transport/NewTransport';
import EditTransport from 'views/Admin/Transport/EditTransport';
import NewLots from 'views/Admin/Lots/NewLots';
import EditLots from 'views/Admin/Lots/EditLots';
import ProfileAdmin from 'views/Admin/Profile/Profile';
import Settings from 'views/Admin/Setting/Setting';
import Cancel from 'views/Checkout/Cancel';
import Success from 'views/Checkout/Success';
import DebtSuccess from 'views/Debt/DebtSuccess';
import DebtCancel from 'views/Debt/DebtCancel';
import TechnicalSupport from 'views/Admin/Support/TechnicalSupport';
import Graph from 'views/Admin/Graph/Graph';
import Destiny from 'views/Admin/Status/Destiny';
import Transit from 'views/Admin/Status/Transit';
import Warehouse from 'views/Admin/Status/Warehouse';
import LabelCreation from 'views/Admin/Status/LabelCreation';
import AdminCalculator from 'views/Admin/Calculator/AdminCalculator';
import EditClient from 'views/Admin/Clients/EditClient';
import UserAddress from 'views/Address/UserAddress';
import DomesticServices from 'views/Services/DomesticServices';
import DomesticOrders from 'views/Admin/Orders/DomesticOrders';
import ViewDomesticOrder from 'views/Admin/Orders/ViewDomesticOrder';

export const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
    layout: "/admin",
    role: [0, 1]
  },
  {
    path: "/admin",
    name: "Welcome",
    component: Welcome,
    layout: "/admin",
    role: [0, 1]
  },
  {
    path: "/agency",
    name: "Agency",
    component: Agency,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/services/:id",
    name: "Services",
    component: ConfigServices,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/domestic/:id",
    name: "Services",
    component: DomesticServices,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/tracking",
    name: "Tracking",
    component: Tracking,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/tracking/:track",
    name: "Tracking",
    component: ViewTracking,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/calculator",
    name: "Calculator",
    component: Calculator,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/purchase",
    name: "Purchase",
    component: Purchase,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/pickup/:id",
    name: "PickUp",
    component: PickupCreation,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/checkout/success",
    name:"Checkout",
    component: Success,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/checkout/cancel",
    name:"Checkout",
    component: Cancel,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/Debt/success",
    name:"Debt",
    component: DebtSuccess,
    layout: "/admin",
    role: [0]
  },
  {
    path: "/Debt/cancel",
    name:"Debt",
    component: DebtCancel,
    layout: "/admin",
    role: [0]
  },
  /* ADMIN */
  {
    path: "/orders",
    name:"Order",
    component: Orders,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/orders/domestic",
    name:"Order",
    component: DomesticOrders,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/orders/domestic/:id",
    name:"Order",
    component: ViewDomesticOrder,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/orders/:id",
    name:"Order",
    component: ViewOrder,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/process",
    name:"Order",
    component: FindOrder,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/process/:id",
    name:"Order",
    component: ProcessOrder,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/search",
    name:"Order",
    component: SearchOrder,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/search/:id",
    name:"Order",
    component: SearchOrderView,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/lots",
    name:"Lot",
    component: Lots,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/lots/new",
    name:"Lot",
    component: NewLots,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/lots/:id",
    name:"Lot",
    component: EditLots,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/products",
    name:"Product",
    component: Products,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/product/new",
    name: "Product",
    component: NewProduct,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/product/:id",
    name: "Product",
    component: EditProduct,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/coupon",
    name:"Coupon",
    component: Coupon,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/coupon/new",
    name: "Coupon",
    component: NewCoupon,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/coupon/:id",
    name: "Coupon",
    component: EditCoupon,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/client/:id",
    name: "Clients",
    component: EditClient,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/address/state",
    name:"State",
    component: State,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/address/state/new",
    name: "State",
    component: NewState,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/address/state/:id",
    name: "State",
    component: EditState,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/address/city",
    name:"State",
    component: City,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/address/city/new",
    name: "State",
    component: NewCity,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/address/city/:id",
    name: "State",
    component: EditCity,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/transports",
    name:"Transport",
    component: Transport,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/transports/new",
    name:"Transport",
    component: NewTransport,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/transports/:id",
    name:"Transport",
    component: EditTransport,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/users",
    name:"Users",
    component: Users,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/users/new",
    name: "Category",
    component: NewUsers,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/users/:id",
    name:"Users",
    component: EditUsers,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/settings",
    name:"Settings",
    component: Settings,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/profile/admin",
    name:"Profile",
    component: ProfileAdmin,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/technical-support",
    name:"Technical Support",
    component: TechnicalSupport,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/graph",
    name:"Graph",
    component: Graph,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/packages/label",
    name:"Label",
    component: LabelCreation,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/packages/warehouse",
    name:"Warehouse",
    component: Warehouse,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/packages/transit",
    name:"Transit",
    component: Transit,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/packages/destiny",
    name:"Package Status",
    component: Destiny,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/calculate",
    name:"Calculator",
    component: AdminCalculator,
    layout: "/admin",
    role: [1]
  },
  {
    path: "/user/address",
    name:"Address",
    component: UserAddress,
    layout: "/admin",
    role: [0]
  },
]
