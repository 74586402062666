/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getTransportsData, getLotByIdData, getLotStatusData, 
  deleteLotPackageData, updateLotData, 
  downloadLabelData, setStatusPackageData, 
  getLotPackagesByIdData, downloadRoutePdfData } from "../../../api/admin";
import { getAllStateData, getCityByStateData } from "../../../api/users";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';
import dayjs from 'dayjs';

export default function EditLots() {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ transport: false, state: false, data: false, btn: false, dataPackages: false, state_id: false, city: false });
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [arrTransport, setArrTransport] = useState([]);
  const [arrPackages, setArrPackages] = useState([]);
  const [arrStatus, setArrStatus] = useState([]);
  const [type, setType] = useState('');
  const [transport, setTransport] = useState('');
  const [shipmentNumber, setShipmentNumber] = useState('');
  const [status, setStatus] = useState('');
  const [tracking, setTracking] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [comment, setComment] = useState('');
  const [packageDelete, setPackageDelete] = useState('');
  const [modalDeletePackage, setModalDeletePackage] = useState(false);
  const [arrState, setArrState] = useState([]);
  const [arrCity, setArrCity] = useState([]);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  useEffect(() => {
    funct();
  }, []);

  const funct = async () => {
    await getTransports();
    await getLotStatus();
    await getLotById();
    await getPackages();
    await getAllStateByAgency();
  }

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getLotById = async () => {
    setLoading({...loading, data: true});

    const res = await getLotByIdData(id);

    if(res.success === true) {
      const _type = (res.data.transport_type === "OCEAN") ? "1" : "2";
      setType(_type);
      setTracking(res.data.tracking);
      setStatus(res.data.status);
      setShipmentNumber(res.data.number);
      setTransport(res.data.transport_id);
      setState(res.data.state);
      await getCityByState(res.data.state);
      setCity(res.data.city);
      setCreatedAt( dayjs(res.data.date).format('MM/DD/YYYY HH:mm'));
      setComment(res.data.comment);
      const _departureDate = (res.data.departure_date === null) ? "" : dayjs(res.data.departure_date).format('YYYY-MM-DD');
      setDepartureDate(_departureDate);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos");
    }

    setLoading({...loading, data: false});
  }

  const getPackages = async () => {
    setLoading({...loading, dataPackages: true});

    const res = await getLotPackagesByIdData(id);

    console.log("PACKAGE !!!", res.data)
    
    if(res.success === true) {
      setArrPackages(res.data);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos");
    }

    setLoading({...loading, dataPackages: false});
  }

  const getLotStatus = async () => {
    setLoading({...loading, status: true});

    const res = await getLotStatusData();

    if(res.success === true) {
      setArrStatus(res.data);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos");
    }

    setLoading({...loading, status: false});
  }

  const getTransports = async () => {
    setLoading({...loading, transport: true});
    
    const res = await getTransportsData();

    if(res.success === true) {
      setArrTransport(res.data);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos");
    }

    setLoading({...loading, transport: false});
  }

  const getAllStateByAgency = async () => {
    setLoading({ ...loading, state_id: true });
    const res = await getAllStateData();
    if(res.success === true) {
      setArrState(res.data);
    }
    else {
      setArrState([]);
    }
    setLoading({ ...loading, state_id: false });
  }

  const getCityByState = async (val) => {
    setLoading({ ...loading, city: true });

    setState(val);
    const res = await getCityByStateData(val);

    if(res.success === true) {
      setArrCity(res.data);
    }
    else {
      setArrCity([]);
    }

    setLoading({ ...loading, city: false});
  }

  const setDataToDelete = (value) => {
    setPackageDelete(value);
    setModalDeletePackage(!modalDeletePackage);
  }

  const deletePackage = async () => {
    const res = await deleteLotPackageData(packageDelete);

    if(res.success === true) {
      setModalDeletePackage(!modalDeletePackage);
      getLotById();
      notifyAlert("success", "Éxito", "Paquete eliminado correctamente");
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al eliminar el paquete");
    }
  }

  const updateData = async () => {
    setLoading({...loading, btn: true});
    
    if(String(transport).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el transportista");
    }
    else if(String(type).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el tipo de envío");
    }
    else if(String(state).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el estado de destino");
    }
    else if(String(city).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar la ciudad de destino");
    }
    else {
      const jsn = {
        "id": id,
        "type": type,
        "transport": transport,
        "state": state,
        "city": city,
        "status": status,
        "departureDate": (departureDate === "") ? null : dayjs(departureDate).format('YYYY-MM-DD'),
        "comment": comment,
        "shipmentNumber": shipmentNumber,
      }

      const res = await updateLotData(jsn);

      if(res.success === true) {
        notifyAlert("success", "Éxito", "El lote se ha actualizado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al actualizar el lote");
      }
    }

    setLoading({...loading, btn: false});
  }

  const downloadLabel = async (id, tracking) => {
    const res = await downloadLabelData(id);

    if(res.success === true) {
      const link = document.createElement('a');
      link.download = `REPACKAGE LABEL - ${String(tracking).toUpperCase()}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
      link.parentNode.removeChild(link);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al descargar la etiqueta");
    }
  }
  
  const setPackageStatus = async (package_id) => {
    const jsn = {
      "id": package_id,
      "status_id": 5
    }

    const res = await setStatusPackageData(jsn);

    if(res.success === true) {
      getPackages();
      notifyAlert("success", "Éxito", "El paquete se ha actualizado correctamente");
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al actualizar el paquete");
    }
  }

  const downloadRoutePdf = async () => {
    setLoadingPDF(true);
    const res = await downloadRoutePdfData(id);

    if(res.success === true) {
      const link = document.createElement('a');
      link.download = `ROUTE REPORT - ${String(tracking).toUpperCase()}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
      link.parentNode.removeChild(link);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al descargar la etiqueta");
    }
    setLoadingPDF(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Lotes - {String(tracking).toUpperCase()} </h3>
                  </div>
                  <div className="col mb-0 float-right" style={{textAlign: "right"}}>
                    {
                      (loadingPDF) ? 
                        <Spinner style={{ color: getColor() }} size="sm" /> : 
                        <>
                          <Button
                            size='sm'
                            style={{
                              backgroundColor: getColor(),
                              color: '#fff',
                              borderColor: getColor()
                            }}
                            onClick={() => downloadRoutePdf()}
                          > Descargar listado </Button>
                        </>
                    }
                  </div>
                  
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Transportista </label>
                        {
                          (loading.data) ? <>
                            <br />
                            <Spinner style={{ color: getColor() }} size="sm" />
                          </> : <>
                            <select
                              style={{
                                appearance: 'auto'
                              }}
                              className="form-control"
                              value={transport}
                              onChange={val => setTransport(val.target.value)}
                            >
                              <option value=""> Seleccionar </option>
                              {
                                arrTransport.map((item, key) => {
                                  return (
                                    <option value={item.id} key={key}> {item.name} </option>
                                  )
                                })
                              }
                            </select>
                          </>
                        }
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Tipo de Envío </label>
                        <select
                          style={{appearance: 'auto' }}
                          className="form-control"
                          value={type}
                          onChange={val => setType(val.target.value)}
                        >
                          <option value=""> Seleccionar </option>
                          <option value="1"> Maritimo </option>
                          <option value="2"> Aereo </option>
                        </select>
                      </FormGroup>
                    </Col>
                    
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Estado </label>
                        {
                          (loading.status) ? <>
                            <br />
                            <Spinner style={{ color: getColor() }} size="sm" />
                          </> : <>
                            <select
                              style={{
                                appearance: 'auto'
                              }}
                              className="form-control"
                              value={status}
                              onChange={val => setStatus(val.target.value)}
                            >
                              <option value=""> Seleccionar </option>
                              {
                                arrStatus.map((item, key) => {
                                  return (
                                    <option value={item.id} key={key}> {item.name} </option>
                                  )
                                })
                              }
                            </select>
                          </>
                        }
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">Seleccione Estado</label>
                        <select
                          className="form-control"
                          value={state}
                          onChange={(e) => getCityByState(e.target.value)}
                          >
                          <option value=""> Seleccionar </option>
                          {
                            (loading.state_id) ? <>
                              <Spinner size='sm' style={{ color: getColor() }}/>
                            </> : <>
                              {
                                arrState.map((item, key) => (
                                  <option key={key} value={item.id}> {item.name} </option>
                                ))
                              }
                            </>
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label">Seleccione Ciudad</label>
                        <select
                          value={city}
                          className="form-control"
                          onChange={(e) => setCity(e.target.value)}>
                          <option value=""> Seleccionar </option>
                          {
                            (loading.city) ? <>
                              <Spinner size='sm' style={{ color: getColor() }}/>
                            </> : <>
                              {
                                arrCity.map((item, key) => (
                                  <option key={key} value={item.id}> {item.name} </option>
                                ))
                              }
                            </>
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Numero de envío </label>
                        <Input 
                          className="form-control"
                          placeholder="Numero de envío"
                          type="text"
                          value={shipmentNumber}
                          onChange={val => setShipmentNumber(val.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Fecha de creacíon </label>
                        <Input 
                          className="form-control"
                          placeholder="Fecha de creacíon"
                          type="text"
                          value={createdAt}
                          disabled={true}
                          onChange={val => setCreatedAt(val.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Fecha de envío </label>
                        <Input 
                          className="form-control"
                          placeholder="Fecha de envío"
                          type="date"
                          value={departureDate}
                          onChange={val => setDepartureDate(val.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Comentarios </label>
                        <Input 
                          className="form-control"
                          placeholder="Comentarios"
                          type="textarea"
                          value={comment}
                          onChange={val => setComment(val.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading.btn) ? <Spinner style={{color: getColor()}}/> :
                          <div>
                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => updateData()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col>
                      <label className="form-control-label"> Paquetes </label>
                    </Col>
                    <br />
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th />
                            <th> Tracking </th>
                            <th> Tipo </th>
                            <th> Cliente </th>
                            <th> Destino </th>
                            <th> Opciones </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (loading.dataPackages) ? <>
                              <tr>
                                <td colSpan="12" className="text-center">
                                  <Spinner style={{ color: getColor() }} size="sm" />
                                </td>
                              </tr>
                            </> : <>
                            {
                              (arrPackages.length === 0) ? <>
                                <tr>
                                  <td colSpan="12" className="text-center">
                                    <h3> No hay paquetes para procesar </h3>
                                  </td>
                                </tr>
                              </> : <>
                                {
                                  arrPackages.map((item, key) => (
                                    <tr key={key}>
                                      <td> {++key} </td>
                                      <td> {String(item.tracking_number).toUpperCase()} </td>
                                      <td> {item.product_type} </td>
                                      <td> {item.client} </td>
                                      <td> {item.address} </td>
                                      <td>
                                        <Button 
                                          size='sm'
                                          style={{
                                            backgroundColor: getColor(),
                                            color: '#fff',
                                            border: getColor()
                                          }}
                                          color='danger'
                                          onClick={() => downloadLabel(item.id, item.tracking_number)}
                                          > Etiqueta </Button>
                                        <Button 
                                          size='sm'
                                          color='danger'
                                          onClick={() => setDataToDelete(item.id)}
                                          > Eliminar </Button>
                                        {
                                          (item.status_id === 4) ? <>
                                            <Button 
                                              size='sm'
                                              color='success'
                                              onClick={() => setPackageStatus(item.id)}
                                            > Entregado </Button>
                                          </> : null
                                        }
                                      </td>
                                    </tr>
                                  ))
                                }
                              </>
                            }
                            </>
                          }
                        </tbody>
                      </Table>
                    </Col> 
                  </Row>

                   <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button color="gray" to="/admin/lots" tag={Link}> Regresar </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

        <Modal isOpen={modalDeletePackage} toggle={() => setModalDeletePackage(!modalDeletePackage)}>
          <ModalHeader toggle={() => setModalDeletePackage()}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar el paquete?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalDeletePackage()}> Cancelar </Button>
            <Button color="danger" onClick={() => deletePackage()}> Eliminar </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}

