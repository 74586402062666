import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

const agencyName = "vboxnow";
//const agencyName = "rimacargo";

export async function checkSession() {
	const token = getToken('vboxToken');

	try {
		const res = await axios.get(CONS.session, {
			headers: {
				'token': token,
        'agency': agencyName
			}
		});
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setLogin(email, pass) {
  let jsn = {
    email: email,
		pass: pass
	}
  
	try {
    const res = await axios.post(CONS.login, jsn, {
      headers: {
        'agency': agencyName
        }
      });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function registerNewUser(jsn) {
  try {
    const res = await axios.post(CONS.register, jsn, {
      headers: {
        'agency': agencyName
        }
      });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setLogOut() {
	try {
		const res = await axios.get(CONS.logout);
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewPassword(email) {
	let jsn = {
		"email": email,
	}

	try {
		const res = await axios.post(CONS.password, jsn, {
      headers: {
        'agency': agencyName
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}