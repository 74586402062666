/* eslint-disable no-mixed-operators */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, InputGroup, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Header from "../../components/Headers/Header";
import { getColor, setToken, getToken, deleteToken } from 'utils/token';
import { getPackageByCodeData, 
  getAllSenderAddressData,
  makeRateDataDomestic,
  createPackageServicesDomesticData } from '../../api/users';
import NotificationAlert from "react-notification-alert";

export default function DomesticServices() {
  const notificationAlertRef = React.useRef(null);
  const { id } = useParams();
  const [data, setData] = useState({ id: 0, type: '', name: '', code: '', price: '', description: '', image: '' });
  const [loading, setLoading] = useState({page: true, coupon: false, stepThree: false, payment: false });
  const [error, setError] = useState({page: false, office: false, addressUS: false});
  const [modal, setModal] = useState({sender: false, receiver: false});
  const [step, setStep] = useState(1);
  const [addressUS, setAddressUS] = useState([]);

  //SENDER
  const [senderData, setSenderData] = useState({
    name: '',
    phone: '',
    email: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zipCode: ''
  });
  
  //RECEIVER
  const [receiverData, setReceiverData] = useState({
    name: '',
    phone: '',
    email: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zipCode: ''
  });

  //ADDITIONAL
  const [additionalData, setAdditionalData] = useState({
    content: "",
    content_value: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    payment: "",
  });

  //PAYMENT
  const [paymentData, setPaymentData] = useState({
    subTotal: 0,
    total: 0
  });

  useEffect( async ()=> {
    getUserData();
    await getPackageByCode();
    setLoading({...loading, page: false});
  }, []) 

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getUserData = () => {
    const user = JSON.parse(getToken('package'));

    if(user !== null) {
      setSenderData({
        name: user.sender.name,
        phone: user.sender.phone,
        email: user.sender.email,
        addressLineOne: user.sender.addressLineOne,
        addressLineTwo: user.sender.addressLineTwo,
        city: user.sender.city,
        state: user.sender.state,
        zipCode: user.sender.zipCode
      });
    }
  }

  const getPackageByCode = async () => {
    const res = await getPackageByCodeData(id);
    
    if(res.success === true) {
      setError({...error, page: false});

      setData({
        id: res.data.id,
        type: res.data.type,
        name: res.data.name,
        code: res.data.code,
        description: res.data.description,
        image: res.data.image
      });

      setPaymentData({...paymentData, 
        subTotal: res.data.price, 
        total: res.data.price
      });

      setAdditionalData({...additionalData, type_tax: '2'});
    }
    else {
      setError({...error, page: true});
    }
  }

  const getUSAddress = async () => {
    const res = await getAllSenderAddressData();

    if(res.success === true) {
      setAddressUS(res.data);
    }
    else {
      setError({...error, addressUS: true});
    }
  }

  const setUSAddress = async (value) => {
    getUSAddress();

    if(value === "sender") {
      setModal({...modal, sender: !modal.sender});
    }
    else if(value === "receiver") {
      setModal({...modal, receiver: !modal.receiver});
    }
  }

  const setAddressSelected = (type, value) => {
    if(type === 'sender') {
      setSenderData({...senderData,
        name: value.name,
        email: value.email,
        phone: value.phone,
        addressLineOne: value.lineOne,
        addressLineTwo: value.lineTwo,
        state: value.state,
        city: value.city,
        zipCode: value.zip_code
      });
  
      setModal({...modal, sender: !modal.sender});
    }
    else if(type === 'receiver') {
      setReceiverData({...receiverData,
        "name": value.name,
        "email": value.email,
        "phone": value.phone,
        "addressLineOne": value.lineOne,
        "addressLineTwo": value.lineTwo,
        "state": value.state,
        "city": value.city,
        "zipCode": value.zip_code
      });

      setModal({...modal, receiver: !modal.receiver});
    }
  }

  const validStepOne = () => {
    if(String(senderData.name).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El nombre del remitente es requerido.');
    }
    else if(String(senderData.email).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El correo electrónico del remitente es requerido.');
    }
    else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(senderData.email)) {
      notifyAlert('warning', 'Mensaje', 'El correo electrónico del remitente no es válido.');
    }
    else if(String(senderData.phone).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El teléfono del remitente es requerido.');
    }
    else if(!/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/.test(senderData.phone)) {
      notifyAlert('warning', 'Mensaje', 'El teléfono del remitente no es válido.');
    }
    else if(String(senderData.addressLineOne).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'La dirección del remitente es requerida.');
    }
    else if(String(senderData.state).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El estado del remitente es requerido.');
    }
    else if(String(senderData.city).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'La ciudad del remitente es requerida.');
    }
    else if(String(senderData.zipCode).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El código postal del remitente es requerido.');
    }
    else {
      var sender_data = {
        "sender": {
          "name": senderData.name,
          "email": senderData.email,
          "phone": senderData.phone,
          "addressLineOne": senderData.addressLineOne,
          "addressLineTwo": senderData.addressLineTwo,
          "state": senderData.state,
          "city": senderData.city,
          "zipCode": senderData.zipCode
        },
        "receive": {
          "name": receiverData.name,
          "email": receiverData.email,
          "phone": receiverData.phone,
          "addressLineOne": receiverData.addressLineOne,
          "addressLineTwo": receiverData.addressLineTwo,
          "state": receiverData.state,
          "city": receiverData.city,
          "zipCode": receiverData.zipCode
        },
        "detail": {},
        "payment": {}
      } 

      setToken("package", JSON.stringify(sender_data));
      setStep(2);
      window.scrollTo(0, 0);
    }
  }

  const validStepTwo = () => {
    if(String(receiverData.name).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El nombre del remitente es requerido.');
    }
    else if(String(receiverData.email).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El correo electrónico del remitente es requerido.');
    }
    else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(receiverData.email)) {
      notifyAlert('warning', 'Mensaje', 'El correo electrónico del remitente no es válido.');
    }
    else if(String(receiverData.phone).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El teléfono del remitente es requerido.');
    }
    else if(!/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/.test(receiverData.phone)) {
      notifyAlert('warning', 'Mensaje', 'El teléfono del remitente no es válido');
    }
    else if(String(receiverData.addressLineOne).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'La dirección del remitente es requerida.');
    }
    else if(String(receiverData.state).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El estado del remitente es requerido.');
    }
    else if(String(receiverData.city).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'La ciudad del remitente es requerida.');
    }
    else if(String(receiverData.zipCode).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El código postal del remitente es requerido.');
    }
    else {
      var sender_data = {
        "sender": {
          "name": senderData.name,
          "email": senderData.email,
          "phone": senderData.phone,
          "addressLineOne": senderData.addressLineOne,
          "addressLineTwo": senderData.addressLineTwo,
          "state": senderData.state,
          "city": senderData.city,
          "zipCode": senderData.zipCode
        },
        "receive": {
          "name": receiverData.name,
          "email": receiverData.email,
          "phone": receiverData.phone,
          "addressLineOne": receiverData.addressLineOne,
          "addressLineTwo": receiverData.addressLineTwo,
          "state": receiverData.state,
          "city": receiverData.city,
          "zipCode": receiverData.zipCode
        },
        "detail": {},
        "payment": {}
      } 

      setToken("package", JSON.stringify(sender_data));
      setStep(3);
      window.scrollTo(0, 0);
    }
  }

  
  const validStepThree = async () => {
    const total_pkg_dim = parseFloat(additionalData.length) + 2 * ( parseFloat(additionalData.width) + parseFloat(additionalData.height) );

    setLoading({...loading, stepThree: true});

    if(String(additionalData.content).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El contenido es requerido.');
    }
    else if(total_pkg_dim > 165) {
      notifyAlert('warning', 'Mensaje', 'El tamaño del paquete no puede ser mayor a 165 pulgadas.');
    }
    else if(String(additionalData.weight).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El peso es requerida.');
    }
    else if(Number(additionalData.weight) < 1 || Number(additionalData.weight) > 150) {
      notifyAlert('warning', 'Mensaje', 'El peso esta fuera de rango. Entre 1 a 150 libras');
      return false;
    }
    else if(String(additionalData.length).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'La longitud es requerida.');
      return false;
    }
    else if(Number(additionalData.length) < 1 || Number(additionalData.length) > 106) {
      notifyAlert('warning', 'Mensaje', 'La longitud esta fuera de rango. Entre 1 a 106 pulgadas');
      return false;
    }
    else if(String(additionalData.width).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'El ancho es requerido.');
      return false;
    }
    else if(Number(additionalData.width) < 1 || Number(additionalData.width) > 106) {
      notifyAlert('warning', 'Mensaje', 'El ancho esta fuera de rango. Entre 1 a 106 pulgadas');
      return false;
    }
    else if(String(additionalData.height).trim() === '') {
      notifyAlert('warning', 'Mensaje', 'La altura es requerida. ');
      return false;
    }
    else if(Number(additionalData.height) < 1 || Number(additionalData.height) > 106) {
      notifyAlert('warning', 'Mensaje', 'La altura esta fuera de rango. Entre 1 a 106 pulgadas');
      return false;
    }
    else {
      const jsn = {
        "origin_zipcode": senderData.zipCode,
        "destination_zipcode": receiverData.zipCode,
        "weight": additionalData.weight,
        "length": additionalData.length,
        "width": additionalData.width,
        "height": additionalData.height
      }

      const res = await makeRateDataDomestic(jsn);

      if(res.success === true) {
        const product = res.data;

        const priceFinal = Number(product.payment);

        setPaymentData({...paymentData,
          subTotal: priceFinal.toFixed(2),
          total: priceFinal.toFixed(2)
        });

        var jsnData = {
          "sender": {
            "name": senderData.name,
            "email": senderData.email,
            "phone": String(senderData.phone).replace(/[\(\)\-]/g, ""),
            "addressLineOne": senderData.addressLineOne,
            "addressLineTwo": senderData.addressLineTwo,
            "state": senderData.state,
            "city": senderData.city,
            "zipCode": senderData.zipCode
          },
          "receive": {
            "name": receiverData.name,
            "email": receiverData.email,
            "phone": String(receiverData.phone).replace(/[\(\)\-]/g, ""),
            "addressLineOne": receiverData.addressLineOne,
            "addressLineTwo": receiverData.addressLineTwo,
            "state": receiverData.state,
            "city": receiverData.city,
            "zipCode": receiverData.zipCode
          },
          "detail": {
            "payment": priceFinal,
            "content": additionalData.content,
            "content_value": additionalData.content_value,
            "weight": additionalData.weight,
            "length": additionalData.length,
            "width": additionalData.width,
            "height": additionalData.height,
          },
          "payment": {}
        } 
        
        setToken("package", JSON.stringify(jsnData));
        setStep(4);
        window.scrollTo(0, 0);
      }
      else  {
        notifyAlert('danger', 'Mensaje', 'Ha ocurrido un problema al calcular el precio, por favor intente luego mas tarde.');
      }
    }

    setLoading({...loading, stepThree: false});
  }

  const createPayment = async () => {
    setLoading({ ...loading, payment: true });
    
    var jsn = {
      "product": {
        "id": data.id,
        "type": data.type
      },
      "sender": {
        "name": senderData.name,
        "email": senderData.email,
        "phone": String(senderData.phone).replace(/[\(\)\-]/g, ""),
        "addressLineOne": senderData.addressLineOne,
        "addressLineTwo": senderData.addressLineTwo,
        "state": senderData.state,
        "city": senderData.city,
        "zipCode": senderData.zipCode
      },
      "receive": {
        "name": receiverData.name,
        "email": receiverData.email,
        "phone": String(receiverData.phone).replace(/[\(\)\-]/g, ""),
        "addressLineOne": receiverData.addressLineOne,
        "addressLineTwo": receiverData.addressLineTwo,
        "state": receiverData.state,
        "city": receiverData.city,
        "zipCode": receiverData.zipCode
      },
      "detail": {
        "content": additionalData.content,
        "content_value": additionalData.content_value,
        "length": additionalData.length,
        "width": additionalData.width,
        "height": additionalData.height,
        "weight": additionalData.weight,
        "payment": additionalData.total,
      },
      "payment": {
        "total": paymentData.total,
      },
      "date": new Date().getTime()
    } 

    console.log(jsn);

    const res = await createPackageServicesDomesticData(jsn);

    if(res.success === true) {
      deleteToken("package");
      setSenderData({name: '', phone: '', email: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipCode: ''});
      setReceiverData({name: '', phone: '', email: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipCode: ''});
      setAdditionalData({content: "", content_value: "", weight: "", length: "", width: "", height: "", payment: ""});
      setPaymentData({subTotal: 0, total: 0});
      window.location.href = res.data;
    }
    else {
      notifyAlert('danger', 'Mensaje', res.message);
    }

    setLoading({ ...loading, payment: false });
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              {
                (loading.page) ? 
                <>
                  <div className="text-center">
                    <br />
                    <Spinner style={{color: getColor()}} />
                    <br /><br /><br /><br />
                  </div>
                </> :
                <>
                  {
                    (error.page) ? <>
                      <div className='text-muted text-center'>
                        <label> Ha ocurrido un error al obtener los datos del servicio solicitado, por favor intente luego mas tarde. </label>
                        <br />
                        <Button color="gray" to="/admin/services" tag={Link}> Regresar </Button>
                      </div>
                    </> : 
                    <>
                      <CardHeader className="border-0">
                        <Row>
                          <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                              <img 
                                alt={data.name}
                                src={data.image}
                                style={{
                                  width: '64px',
                                }}
                              />
                              <h3> {data.name} </h3>
                            </div>
                            {
                              (step === 1) ? <> 
                                <div style={{textAlign: "right"}}>
                                  <Button
                                    size='sm'
                                    style={{
                                      backgroundColor: getColor(),
                                      color: '#fff',
                                      borderColor: getColor()
                                    }}
                                    onClick={() => setUSAddress("sender")}
                                  > Cargar direcciones </Button>
                                </div>
                              </> : 
                              (step === 2) ? <>
                                <div style={{textAlign: "right"}}>
                                  <Button
                                    size='sm'
                                    style={{
                                      backgroundColor: getColor(),
                                      color: '#fff',
                                      borderColor: getColor()
                                    }}
                                    onClick={() => setUSAddress("receiver")}
                                  > Cargar direcciones </Button>
                                </div>
                              </> : <></>
                            }
                          </div>
                        </Row>
                      </CardHeader>

                      <CardBody>
                        <div className="pl-lg-4">
                          {/* STEPS */}
                          <Row>
                            <Col md="3">
                              <ul style={{padding: '0px', listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                                <li>
                                  <div>
                                    <div 
                                      style={{ 
                                        backgroundColor: (step === 1) ? getColor() : "#8C8C8C", 
                                        fontWeight: 'bold', 
                                        fontSize: '17px', 
                                        marginRight: '10px', 
                                        width: "40px", 
                                        height: "40px"
                                      }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                      1
                                    </div>
                                  </div>
                                </li>
                                <li style={{color: (step === 1) ? getColor() : "#8C8C8C", fontWeight: 'bold', fontSize: '15px' }}>
                                  QUIÉN ENVÍA
                                </li>
                              </ul>
                            </Col>
                            <Col md="3">
                              <ul style={{padding: '0px', listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                                <li>
                                  <div>
                                    <div 
                                      style={{ 
                                        backgroundColor: (step === 2) ? getColor() : "#8C8C8C", 
                                        fontWeight: 'bold', 
                                        fontSize: '17px', 
                                        marginRight: '10px', 
                                        width: "40px", 
                                        height: "40px"
                                      }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                      2
                                    </div>
                                  </div>
                                </li>
                                <li style={{color: (step === 2) ? getColor() : "#8C8C8C", fontWeight: 'bold', fontSize: '15px' }}>
                                  QUIÉN RECIBE
                                </li>
                              </ul>
                            </Col>
                            <Col md="3">
                              <ul style={{padding: '0px', listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                                <li>
                                  <div>
                                    <div 
                                      style={{ backgroundColor: (step === 3) ? getColor() : "#8C8C8C",fontWeight: 'bold', fontSize: '17px', marginRight: '10px', width: "40px", height: "40px" }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                      3
                                    </div>
                                  </div>
                                </li>
                                <li style={{color: (step === 3) ? getColor() : "#8C8C8C", fontWeight: 'bold', fontSize: '15px' }}>
                                  DATOS ADICIONALES
                                </li>
                              </ul>
                            </Col>
                            <Col md="3">
                              <ul style={{padding: '0px', listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                                <li>
                                  <div>
                                    <div 
                                      style={{ backgroundColor: (step === 4) ? getColor() : "#8C8C8C",fontWeight: 'bold', fontSize: '17px', marginRight: '10px', width: "40px", height: "40px"  }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                      4
                                    </div>
                                  </div>
                                </li>
                                <li style={{color: (step === 4) ? getColor() : "#8C8C8C", fontWeight: 'bold', fontSize: '15px' }}>
                                  INFORMACIÓN DE PAGO
                                </li>
                              </ul>
                            </Col>
                          </Row>
                          {
                            (step === 1) ? <>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label"> Nombre </label>
                                    <Input
                                      value={senderData.name}
                                      className="form-control"
                                      onChange={(e) => setSenderData({...senderData, name: e.target.value})} />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label"> Email </label>
                                    <Input
                                      value={senderData.email}
                                      className="form-control"
                                      onChange={(e) => setSenderData({...senderData, email: e.target.value})} />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label"> Teléfono </label>
                                    <InputGroup>
                                      <Input
                                        value={senderData.phone}
                                        className="form-control"
                                        onChange={(e) => setSenderData({...senderData, phone: e.target.value})} />
                                    </InputGroup>
                                    <small className='text-muted'> Podremos enviar mensajes de SMS con el estatus de tu paquete a este número de teléfono. </small>
                                  </FormGroup>
                                </Col>

                                <Col lg="12">
                                  <FormGroup>
                                    <label className="form-control-label"> Dirección </label>
                                    <Input
                                      value={senderData.addressLineOne}
                                      className="form-control"
                                      onChange={(e) => setSenderData({...senderData, addressLineOne: e.target.value})} />
                                    <br /> 
                                    <Input
                                      value={senderData.addressLineTwo}
                                      className="form-control" 
                                      onChange={(e) => setSenderData({...senderData, addressLineTwo: e.target.value})} />
                                  </FormGroup>
                                </Col>

                                <Col lg="4">
                                  <FormGroup>
                                    <label className="form-control-label"> Estado </label>
                                    <select 
                                      value={senderData.state}
                                      className='form-control'
                                      onChange={(val) => setSenderData({...senderData, state: val.target.value})}>
                                      <option value=""> Seleccionar </option>
                                      <option value="AL"> Alabama </option>
                                      <option value="AZ"> Arizona </option>
                                      <option value="AR"> Arkansas </option>
                                      <option value="CA"> California </option>
                                      <option value="CO"> Colorado </option>
                                      <option value="CT"> Connecticut </option>
                                      <option value="DE"> Delaware </option>
                                      <option value="FL"> Florida </option>
                                      <option value="GA"> Georgia </option>
                                      <option value="ID"> Idaho </option>
                                      <option value="IL"> Illinois </option>
                                      <option value="IN"> Indiana </option>
                                      <option value="IA"> Iowa </option>
                                      <option value="KS"> Kansas </option>
                                      <option value="KY"> Kentucky </option>
                                      <option value="LA"> Louisiana </option>
                                      <option value="ME"> Maine </option>
                                      <option value="MD"> Maryland </option>
                                      <option value="MA"> Massachusetts </option>
                                      <option value="MI"> Michigan </option>
                                      <option value="MN"> Minnesota </option>
                                      <option value="MS"> Mississippi </option>
                                      <option value="MO"> Missouri </option>
                                      <option value="MT"> Montana </option>
                                      <option value="NE"> Nebraska </option>
                                      <option value="NV"> Nevada </option>
                                      <option value="NH"> New Hampshire </option>
                                      <option value="NJ"> New Jersey </option>
                                      <option value="NM"> New Mexico </option>
                                      <option value="NY"> New York </option>
                                      <option value="NC"> North Carolina </option>
                                      <option value="ND"> North Dakota </option>
                                      <option value="OH"> Ohio </option>
                                      <option value="OK"> Oklahoma </option>
                                      <option value="OR"> Oregon </option>
                                      <option value="PA"> Pennsylvania </option>
                                      <option value="RI"> Rhode Island </option>
                                      <option value="SC"> South Carolina </option>
                                      <option value="SD"> South Dakota </option>
                                      <option value="TN"> Tennessee </option>
                                      <option value="TX"> Texas </option>
                                      <option value="UT"> Utah </option>
                                      <option value="VT"> Vermont </option>
                                      <option value="VA"> Virginia </option>
                                      <option value="WA"> Washington </option>
                                      <option value="WV"> West Virginia </option>
                                      <option value="WI"> Wisconsin </option>
                                      <option value="WY"> Wyoming </option>
                                    </select>
                                  </FormGroup>
                                </Col>

                                <Col lg="4">
                                  <FormGroup>
                                    <label className="form-control-label"> Ciudad </label>
                                    <Input
                                      value={senderData.city}
                                      className="form-control"
                                      onChange={(e) => setSenderData({...senderData, city: e.target.value})} />
                                  </FormGroup>
                                </Col>

                                <Col lg="4">
                                  <FormGroup>
                                    <label className="form-control-label"> Código postal </label>
                                    <Input
                                      value={senderData.zipCode}
                                      className="form-control"
                                      onChange={(e) => setSenderData({...senderData, zipCode: e.target.value})} />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="12" >
                                  <br />
                                  <div className="float-right">
                                    <Button color="gray" to="/admin/services" tag={Link}> Regresar </Button>
                                    <Button
                                      style={{
                                        backgroundColor: getColor(),
                                        borderColor: getColor(),
                                        color: "#fff",
                                        fontWeight: "bold",
                                        width: "150px",
                                      }}
                                      onClick={() => validStepOne()}
                                    > Siguiente </Button>
                                  </div>
                                </Col>
                              </Row>
                            </> : 
                            (step === 2) ? <>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label"> Nombre </label>
                                    <Input
                                      value={receiverData.name}
                                      className="form-control"
                                      onChange={(e) => setReceiverData({...receiverData, name: e.target.value})} />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label"> Email </label>
                                    <Input
                                      value={receiverData.email}
                                      className="form-control"
                                      onChange={(e) => setReceiverData({...receiverData, email: e.target.value})} />
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label"> Teléfono </label>
                                    <InputGroup>
                                      <Input
                                        value={receiverData.phone}
                                        className="form-control"
                                        onChange={(e) => setReceiverData({...receiverData, phone: e.target.value})} />
                                    </InputGroup>
                                  </FormGroup>
                                </Col>

                                <Col lg="12">
                                  <FormGroup>
                                    <label className="form-control-label"> Dirección </label>
                                    <Input
                                      value={receiverData.addressLineOne}
                                      className="form-control"
                                      onChange={(e) => setReceiverData({...receiverData, addressLineOne: e.target.value})} />
                                    <br /> 
                                    <Input
                                      value={receiverData.addressLineTwo}
                                      className="form-control" 
                                      onChange={(e) => setReceiverData({...receiverData, addressLineTwo: e.target.value})} />
                                  </FormGroup>
                                </Col>

                                <Col lg="4">
                                  <FormGroup>
                                    <label className="form-control-label"> Estado </label>
                                    <select 
                                      value={receiverData.state}
                                      className='form-control'
                                      onChange={(val) => setReceiverData({...receiverData, state: val.target.value})}>
                                      <option value=""> Seleccionar </option>
                                      <option value="AL"> Alabama </option>
                                      <option value="AZ"> Arizona </option>
                                      <option value="AR"> Arkansas </option>
                                      <option value="CA"> California </option>
                                      <option value="CO"> Colorado </option>
                                      <option value="CT"> Connecticut </option>
                                      <option value="DE"> Delaware </option>
                                      <option value="FL"> Florida </option>
                                      <option value="GA"> Georgia </option>
                                      <option value="ID"> Idaho </option>
                                      <option value="IL"> Illinois </option>
                                      <option value="IN"> Indiana </option>
                                      <option value="IA"> Iowa </option>
                                      <option value="KS"> Kansas </option>
                                      <option value="KY"> Kentucky </option>
                                      <option value="LA"> Louisiana </option>
                                      <option value="ME"> Maine </option>
                                      <option value="MD"> Maryland </option>
                                      <option value="MA"> Massachusetts </option>
                                      <option value="MI"> Michigan </option>
                                      <option value="MN"> Minnesota </option>
                                      <option value="MS"> Mississippi </option>
                                      <option value="MO"> Missouri </option>
                                      <option value="MT"> Montana </option>
                                      <option value="NE"> Nebraska </option>
                                      <option value="NV"> Nevada </option>
                                      <option value="NH"> New Hampshire </option>
                                      <option value="NJ"> New Jersey </option>
                                      <option value="NM"> New Mexico </option>
                                      <option value="NY"> New York </option>
                                      <option value="NC"> North Carolina </option>
                                      <option value="ND"> North Dakota </option>
                                      <option value="OH"> Ohio </option>
                                      <option value="OK"> Oklahoma </option>
                                      <option value="OR"> Oregon </option>
                                      <option value="PA"> Pennsylvania </option>
                                      <option value="RI"> Rhode Island </option>
                                      <option value="SC"> South Carolina </option>
                                      <option value="SD"> South Dakota </option>
                                      <option value="TN"> Tennessee </option>
                                      <option value="TX"> Texas </option>
                                      <option value="UT"> Utah </option>
                                      <option value="VT"> Vermont </option>
                                      <option value="VA"> Virginia </option>
                                      <option value="WA"> Washington </option>
                                      <option value="WV"> West Virginia </option>
                                      <option value="WI"> Wisconsin </option>
                                      <option value="WY"> Wyoming </option>
                                    </select>
                                  </FormGroup>
                                </Col>

                                <Col lg="4">
                                  <FormGroup>
                                    <label className="form-control-label"> Ciudad </label>
                                    <Input
                                      value={receiverData.city}
                                      className="form-control"
                                      onChange={(e) => setReceiverData({...receiverData, city: e.target.value})} />
                                  </FormGroup>
                                </Col>

                                <Col lg="4">
                                  <FormGroup>
                                    <label className="form-control-label"> Código postal </label>
                                    <Input
                                      value={receiverData.zipCode}
                                      className="form-control"
                                      onChange={(e) => setReceiverData({...receiverData, zipCode: e.target.value})} />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12" >
                                  <br />
                                  <div className="float-right">
                                    <Button 
                                      color="gray"
                                      onClick={() => setStep(1)}
                                      > Regresar </Button>
                                    <Button
                                      style={{
                                        backgroundColor: getColor(),
                                        borderColor: getColor(),
                                        color: "#fff",
                                        fontWeight: "bold",
                                        width: "150px",
                                      }}
                                      onClick={() => validStepTwo()}
                                    > Siguiente </Button>
                                  </div>
                                </Col>
                              </Row>
                            </> :
                            (step === 3) ? <>
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <label className="form-control-label"> Declaración del Contenido del Paquete </label>
                                    <Input
                                      value={additionalData.content}
                                      type='textarea'
                                      className="form-control" 
                                      onChange={(e) => setAdditionalData({...additionalData, content: e.target.value})} />
                                  </FormGroup>
                                </Col>
                              
                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label"> Declaración del Valor del Contenido (en USD) </label>
                                    <Input
                                      value={additionalData.content_value}
                                      type='number'
                                      min={1}
                                      className="form-control"
                                      onChange={(e) => setAdditionalData({...additionalData, content_value: e.target.value})} />
                                  </FormGroup>
                                </Col>

                              </Row>

                              <hr />

                              <h3> Dimensiones del paquete </h3>
                              <Row>
                                <Col lg="3" >
                                  <FormGroup>
                                    <label className="form-control-label"> Peso (libras) </label>
                                    <Input
                                      onChange={(e) => setAdditionalData({...additionalData, weight: e.target.value})}
                                      value={additionalData.weight}
                                      type='number'
                                      className="form-control"
                                      max={100}
                                      min={1}/>
                                  </FormGroup>
                                </Col>

                                <Col lg="3">
                                  <FormGroup>
                                    <label className="form-control-label"> Largo (pulgadas) </label>
                                    <Input
                                      onChange={(e) => setAdditionalData({...additionalData, length: e.target.value})}
                                      value={additionalData.length}
                                      type='number'
                                      className="form-control"
                                      max={106}
                                      min={1}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="3">
                                  <FormGroup>
                                    <label className="form-control-label"> Ancho (pulgadas) </label>
                                    <Input
                                      className="form-control"
                                      onChange={(e) => setAdditionalData({...additionalData, width: e.target.value})}
                                      value={additionalData.width}
                                      type='number'
                                      max={106}
                                      min={1}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="3">
                                  <FormGroup>
                                    <label className="form-control-label"> Alto (pulgadas) </label>
                                    <Input
                                      className="form-control" 
                                      onChange={(e) => setAdditionalData({...additionalData, height: e.target.value})}
                                      value={additionalData.height}
                                      type='number'
                                      max={106}
                                      min={1}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg="12" >
                                  <br />
                                  <div className="float-right">
                                    <Button 
                                      color="gray"
                                      onClick={() => setStep(2)}
                                      > Regresar </Button>
                                    
                                    {
                                      (loading.stepThree) ? <>
                                        <Spinner style={{
                                          color: getColor(),
                                        }} /> 
                                      </> : <>
                                        <Button
                                          style={{
                                            backgroundColor: getColor(),
                                            borderColor: getColor(),
                                            color: "#fff",
                                            fontWeight: "bold",
                                            width: "150px",
                                          }}
                                          onClick={() => validStepThree()}
                                        > Siguiente </Button>
                                      </>
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </> :
                            (step === 4) ? <>
                              <Row>
                                <Col lg="6">
                                  <h3> Detalle </h3>
                                  <ul style={{
                                    padding: '0px',
                                    listStyle: 'none',
                                    textAlign: 'center',
                                  }}>
                                    <li> 
                                      <img 
                                        alt="..."
                                        src={data.image} 
                                        style={{
                                          width: "250px", 
                                          height: "auto"
                                        }} />
                                    </li>
                                    <li> <b> {data.name} </b> </li>
                                  </ul>
                                </Col>

                                <Col lg="6">
                                  <h3> Información del pago </h3>
                                  <ul style={{
                                    padding: '0px',
                                    listStyle: 'none',
                                    lineHeight: '2',
                                  }}>
                                    <li style={{display: "flex", justifyContent: "space-between"}}>
                                      <label className="form-control-label"> {data.name} </label>
                                      <span> USD {Number(paymentData.total).toFixed(2)} </span>
                                    </li>
                                  </ul>

                                  <FormGroup>
                                    <label className="form-control-label"> Total </label>
                                    <Input
                                      disabled={true}
                                      value={`USD ${Number(paymentData.total).toFixed(2).toString()}`}
                                      className="form-control" />
                                  </FormGroup>
                                </Col>

                                <Col lg="12" >
                                  <br />
                                  <div className="float-right">
                                    {
                                      (loading.payment) ? <>
                                        <Spinner style={{
                                          color: getColor(),
                                        }} />
                                      </>: 
                                      <>
                                        <Button color="gray" to="/admin/services" tag={Link}> Regresar </Button>
                                        
                                        <Button
                                          style={{
                                            backgroundColor: getColor(),
                                            borderColor: getColor(),
                                            color: "#fff",
                                            fontWeight: "bold",
                                            width: "150px",
                                          }}
                                          onClick={() => createPayment()}
                                        > Realizar Pago </Button>
                                      </>
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </> : <></>
                          }
                        </div>
                      </CardBody>
                    </>
                  }
                </>
              }
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal.sender} toggle={() => setModal({...modal, sender: !modal.sender})}>
        <ModalHeader toggle={() => setModal({...modal, sender: !modal.sender})}>Direciones</ModalHeader>
        <ModalBody>
          <Table responsive>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Estado</th>
                <th>Ciudad</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                addressUS.map((d, i) => (
                  <tr key={i}>
                    <td>{d.title}</td>
                    <td>{d.state}</td>
                    <td>{d.city}</td>
                    <td>
                      <Button
                        size='sm'
                        style={{
                          backgroundColor: getColor(),
                          borderColor: getColor(),
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                        onClick={() => setAddressSelected("sender", d)}
                      > Seleccionar </Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button 
            color="secondary" onClick={() => setModal({...modal, sender: !modal.sender})}>
            Cerrar
          </Button>{' '}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal.receiver} toggle={() => setModal({...modal, receiver: !modal.receiver})}>
        <ModalHeader toggle={() => setModal({...modal, receiversender: !modal.receiver})}>Direciones</ModalHeader>
        <ModalBody>
          <Table responsive>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Estado</th>
                <th>Ciudad</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                addressUS.map((d, i) => (
                  <tr key={i}>
                    <td>{d.title}</td>
                    <td>{d.state}</td>
                    <td>{d.city}</td>
                    <td>
                      <Button
                        size='sm'
                        style={{
                          backgroundColor: getColor(),
                          borderColor: getColor(),
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                        onClick={() => setAddressSelected("receiver", d)}
                      > Seleccionar </Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button 
            color="secondary" onClick={() => setModal({...modal, receiver: !modal.receiver})}>
            Cerrar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  )
}

