import React, { useState } from 'react';
import { CardBody, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import OpenHeader from 'components/Headers/OpenHeader';
import OpenNational from './components/OpenNational';
import OpenInternational from './components/OpenInternational';

export default function Calculator() {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <>
      <Container fluid>
        <OpenHeader />
        <Row>
          <div className="col">
            <div style={{ marginBottom: '-10px'}}>
              <Row>
                <div className="col text-center">
                  <h3 style={{
                    fontSize: '2.2rem',
                    fontWeight: 'bold',
                  }}> ¿Cuanto tengo que pagar?</h3>
                  <label style={{
                    fontSize: '1.5rem',
                  }}> Calculadora de envío </label>
                </div>
              </Row>

              <CardBody>
                <div className='paddingHorizontal'>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '1') ? 'active' : ''}
                        onClick={() => toggle('1') }
                      >
                        Internacional
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '2') ? 'active' : ''}
                        onClick={() => toggle('2')}
                      >
                        Domestico (USA)
                      </NavLink>
                    </NavItem>
                  </Nav>
                  
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <OpenInternational />
                    </TabPane>
                    <TabPane tabId="2">
                      <OpenNational />
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}
