import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { setNewTransportData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';

export default function NewTransport() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [descrption, setDescription] = useState('');
  const [contact, setContact] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState('0');

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const addNewData = async () => {
    setLoading(true);
    
    if(String(name).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el nombre de la empresa de transporte");
    }
    else if(String(contact).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el nombre del contacto");
    }
    else if (String(phone).trim() === "") {
      notifyAlert("danger", "Error", "Debe ingresar el teléfono del contacto");
    }
    else {
      const jsn = {
        "name": name,
        "description": descrption,
        "contact_info": contact,
        "phone": phone,
        "email": email,
        "active": active,
      }

      const res = await setNewTransportData(jsn);

      if(res.success === true) {
        setName("");
        setDescription("");
        setContact("");
        setPhone("");
        setEmail("");
        setActive('0');
        notifyAlert("success", "Éxito", "El transportista se ha creado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al crear el transportista, por favor intente nuevamente");
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Transporte </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Nombre </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> Descripcion </label>
                        <Input
                          type='textarea'
                          value={descrption}
                          className="form-control"
                          onChange={val => setDescription(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Contacto </label>
                        <Input
                          value={contact}
                          className="form-control"
                          onChange={val => setContact(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Telefono </label>
                        <Input
                          value={phone}
                          className="form-control"
                          onChange={val => setPhone(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Email </label>
                        <Input
                          value={email}
                          className="form-control"
                          onChange={val => setEmail(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label"> Activo </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1"> Si </option>
                          <option value="0"> No </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <Spinner style={{color: getColor()}}/> :
                          <div>
                            <Button color="gray" to="/admin/transports" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => addNewData()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}
