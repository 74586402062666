import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getAllPurchaseData() {
	const token = getToken('vboxToken');

	try {
		const res = await axios.get(CONS.purchase, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getSenderContactData(id) {
	const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.purchase}/contact/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function createPickupData(jsn) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.post(CONS.purchase, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function cancelPickUpData(id) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.put(`${CONS.purchase}/cancel`, {"id": id}, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function downloadLabelData(id) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.purchase}/label/${id}`, {
      headers: {
        'token': token
      }
    });
		return res;
	}
	catch(err) {
		return err;
	}
}

export async function getDataByTrackingData(track) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.purchase}/tracking/${track}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function payPendingDebtData(jsn) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.post(`${CONS.purchase}/debt`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function downloadInvoiceData(tracking_number) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.purchase}/invoice/${tracking_number}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getDomesticDataByTrackingData(tracking_number) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.get(`${CONS.purchase}/domestic/status/${tracking_number}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function createPickupWarehouseData(jsn) {
  const token = getToken('vboxToken');

	try {
		const res = await axios.post(`${CONS.purchase}/pickup/warehouse`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}
