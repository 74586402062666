/* eslint-disable no-unused-vars */
var BASE_URL;
const URL = window.location.href;
const _local = String(URL).includes('localhost');

if(_local) {
  BASE_URL = "http://localhost:3001/api";
}
else {
  BASE_URL = `https://vboxnow-back.azurewebsites.net/api`;
}

export const session               = `${BASE_URL}/auth/session`;
export const login                 = `${BASE_URL}/auth/login`;
export const password              = `${BASE_URL}/auth/password`;
export const register              = `${BASE_URL}/auth/register`;
export const logout                = `${BASE_URL}/logout`;
export const support               = `${BASE_URL}/support`;
export const places                = `${BASE_URL}/places`;
export const profile               = `${BASE_URL}/profile`;
export const calculator            = `${BASE_URL}/calculator`;
export const services              = `${BASE_URL}/services`;
export const purchase              = `${BASE_URL}/purchase`;
export const product               = `${BASE_URL}/product`;
export const coupon                = `${BASE_URL}/coupon`;
export const address               = `${BASE_URL}/address`;
export const admin                 = `${BASE_URL}/admin`;
export const order                 = `${BASE_URL}/order`;
export const transport             = `${BASE_URL}/transport`;
export const lots                  = `${BASE_URL}/lots`;
export const clients               = `${BASE_URL}/clients`;
export const open                  = `${BASE_URL}/open`;
export const graph                 = `${BASE_URL}/graph`;