/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Header from "../../../components/Headers/Header";
import {Spinner, Card, CardHeader, CardBody, Container, Row, Col, Button, Table, InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import dayjs from 'dayjs';
import { getColor } from "utils/token";
import { getClientByIdData, getAllClientInvoiceData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";

export default function EditClient () {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ page: false, payBtn: false });
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    "id": "",
    "name": "",
    "email": "",
    "address": "",
    "state": "",
    "city": "",
    "phone": "",
    "zip_code": "",
    "picture": "",
    "join_date": "",
    "locker": ""
  });
  const [packages, setPackages] = useState([]);
  const [sender, setSender] = useState([]);
  const [receiver, setReceiver] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invoiceSearch, setInvoiceSearch] = useState([]);
  const [search, setSearch] = useState('');
  
  useEffect(() => {
    window.scrollTo(0, 0);
    funct();
  }, []);
  
  const funct = async () => {
    await getDataById();
    await getAllClientInvoice();
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = invoices.filter( (item) => {
        const itemData = item.tracking
          ? item.tracking.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setInvoiceSearch(newData);
    } 
    else {
      setSearch(text);
      setInvoiceSearch(invoices);
    }
  }

  const getDataById = async () => {
    setLoading({...loading, page: true});
    const res = await getClientByIdData(id);

    if(res.success === true) {
      setError(false);
      setData({
        "id": res.data.user_data.id,
        "name": res.data.user_data.name,
        "email": res.data.user_data.email,
        "address": res.data.user_data.address,
        "state": res.data.user_data.state,
        "city": res.data.user_data.city,
        "phone": res.data.user_data.phone,
        "zip_code": res.data.user_data.zip_code,
        "picture": res.data.user_data.picture,
        "join_date": dayjs(res.data.user_data.join_date).format("DD/MM/YYYY"),
        "locker": res.data.user_data.locker
      });
      setPackages(res.data.packages);
      setSender(res.data.sender);
      setReceiver(res.data.receiver);
    }
    else {
      setError(true);
      setData({});
    }

    setLoading({...loading, page: false});
  }

  const getAllClientInvoice = async () => {
    const res = await getAllClientInvoiceData(id);

    if(res.success === true) {
      setError(false);
      setInvoices(res.data);
      setInvoiceSearch(res.data);
    }
    else {
      setError(true);
      setInvoices([]);
    }
  }

  const getProductType = (product_type) => {
    switch (product_type) {
      case "OCEAN":
        return "Marítimo";
      case "AIR":
        return "Aéreo";
      case "GROUND":
        return "Domestico";
      default:
        return "No definido";
    }
  }

  const validAddress = (address, state, city, zip_code) => {
    if(address === null || state === null || city === null || zip_code === null) {
      return "No hay información registrada";
    }
    else {
      return `${address}, ${state}, ${city}, ${zip_code}`;
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card 
              style={{
                border:  (data.payment === 1) ? "3px solid #f5365c" : ""
              }}
            >
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Cliente </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizontal'>
                  {
                    (loading.page === true) ? <>
                      <div className="text-center">
                        <Spinner 
                          style={{
                            color: getColor
                          }}
                        />
                      </div>
                    </> : <>
                      {
                        (error === true) ? <>
                          <div className="text-center">
                            <h3> No se encontraron resultados </h3>
                          </div>
                        </> : <>
                          <Row>
                            <Col style={{textAlign: "center"}}>
                              <img 
                                style={{
                                  width: '200px',
                                }}
                                alt="label"
                                src={
                                  (data.picture === null) ? 
                                  require("../../../assets/img/noPicture.png").default :
                                  data.picture
                                } />
                            </Col>

                            <Col>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                              }}>
                                <li>
                                  <h2> {data.name} </h2>
                                </li>
                                <li>
                                  <label> <b>📧 Email: </b> {data.email} </label>
                                </li>
                                <li>
                                  <label> <b>📞 Teléfono: </b> {data.phone} </label>
                                </li>
                                <li>
                                  <label> <b>📍 Dirección: </b> {validAddress(data.address, data.state, data.city, data.zip_code)} </label>
                                </li>
                                <li>
                                  <label> <b>📦 Casillero: </b> {data.locker} </label>
                                </li>
                                <li>
                                  <label> <b>📅 Registrado: </b> {data.join_date} </label>
                                </li>
                              </ul>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <br />
                              <h3> Ultimos 5 paquetes </h3>

                              <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col"> Tipo de envio </th>
                                    <th scope="col"> Tracking </th>
                                    <th scope="col"> Estado </th>
                                    <th scope="col"> Precio </th>
                                    <th scope="col"> Direccion </th>
                                  </tr>
                                </thead>

                                <tbody>
                                {
                                (loading.page) ?
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <Spinner style={{color: '#004899'}} />
                                  </td>
                                </tr>:
                                (packages.length === 0) ? 
                                <tr>
                                  <td colSpan={10} className='text-muted text-center'> No hay información registrada. </td>
                                </tr> :
                                  packages.map( (d,i) => (
                                    <tr 
                                      key={i}
                                      style={{
                                        color: (d.status_id === 1 && d.pickup === 1) ? "#FFB500" :
                                        (d.payment === 1) ? "#F5365C" : "#525F7F"
                                      }}
                                    >
                                      <td>
                                        {getProductType(d.type)}
                                      </td>
                                      <td>
                                        <Link 
                                          to={`/admin/orders/${d.id}`}>
                                          {String(d.tracking_number).toUpperCase()}
                                        </Link>
                                      </td>
                                      <td>
                                        {d.status}
                                      </td>
                                      <td>
                                        ${d.price}
                                      </td>
                                      <td>
                                        {d.address}
                                      </td>
                                    </tr>
                                  ))
                                }
                                </tbody>
                              </Table> 
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <br />
                              <h3> Direcciones de envio </h3>

                              <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col"> Titulo </th>
                                    <th scope="col"> Contacto </th>
                                    <th scope="col"> Dirección </th>
                                  </tr>
                                </thead>

                                <tbody>
                                {
                                (loading.page) ?
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <Spinner style={{color: '#004899'}} />
                                  </td>
                                </tr>:
                                (sender.length === 0) ? 
                                <tr>
                                  <td colSpan={10} className='text-muted text-center'> No hay información registrada. </td>
                                </tr> :
                                  sender.map( (s,i) => (
                                    <tr key={i}>
                                      <td>
                                        {s.title}
                                      </td>
                                      <td>
                                        {s.name}
                                      </td>
                                      <td>
                                        {s.address}, {s.state}, {s.city}, {s.zip_code}
                                      </td>
                                    </tr>
                                  ))
                                }
                                </tbody>
                              </Table> 
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <br />
                              <h3> Direcciones de entrega </h3>

                              <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col"> Titulo </th>
                                    <th scope="col"> Contacto </th>
                                    <th scope="col"> E-mail </th>
                                    <th scope="col"> Dirección </th>
                                  </tr>
                                </thead>

                                <tbody>
                                {
                                (loading.page) ?
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <Spinner style={{color: '#004899'}} />
                                  </td>
                                </tr>:
                                (receiver.length === 0) ? 
                                <tr>
                                  <td colSpan={10} className='text-muted text-center'> No hay información registrada. </td>
                                </tr> :
                                  receiver.map( (r,i) => (
                                    <tr key={i}>
                                      <td>
                                        {r.title}
                                      </td>
                                      <td>
                                        {r.name}
                                      </td>
                                      <td>
                                        {r.email}
                                      </td>
                                      <td>
                                        {r.address}, {r.city_name}, {r.state_name}
                                      </td>
                                    </tr>
                                  ))
                                }
                                </tbody>
                              </Table> 
                            </Col>
                          </Row>
                          
                          <Row>
                            <Col>
                              <h3> Facturas </h3>
                              <div className='paddingHorizonal'>
                                <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    value={search}
                                    placeholder="Buscar"
                                    onChange={(val) => handleSearch(val.target.value)}
                                  />
                                </InputGroup>
                                <br />
                              </div>

                              <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col"> Tracking </th>
                                    <th scope="col"> Tipo </th>
                                    <th scope="col"> Total </th>
                                    <th scope="col"> Fecha </th>
                                    <th scope="col" />
                                  </tr>
                                </thead>

                                <tbody>
                                {
                                (loading.page) ?
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <Spinner style={{color: '#004899'}} />
                                  </td>
                                </tr>:
                                (invoices.length === 0) ? 
                                <tr>
                                  <td colSpan={10} className='text-muted text-center'> No hay información registrada. </td>
                                </tr> :
                                  invoiceSearch.map( (r,i) => (
                                    <tr key={i}>
                                      <td>
                                        {String(r.tracking).toUpperCase()}
                                      </td>
                                      <td>
                                        {getProductType(r.type)}
                                      </td>
                                      <td>
                                        {Number(r.total).toFixed(2)}
                                      </td>
                                      <td>
                                        {dayjs(r.date).format("DD/MM/YYYY")}
                                      </td>
                                      <td>
                                        <Button
                                          disabled={true}
                                          size='sm'
                                          color="primary"
                                          onClick={() => {
                                            window.open(r.url, "_blank");
                                          }}
                                        >
                                          Descargar factura
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                }
                                </tbody>
                              </Table> 
                            </Col>
                          </Row>


                          <Row>
                            <Col lg="12" >
                              <br />
                              <div className="float-right">
                                <Button color="gray" to="/admin/clients" tag={Link}> Regresar </Button>
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
