import axios from "axios";
import * as CONS from "./constants";

const agencyName = "vboxnow";

export async function makeRateData(jsn) {
  try {
    const res = await axios.post(`${CONS.open}/calculator`, jsn, {
      headers: {
        'agency': agencyName
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getAllStateData() {
  try {
    const res = await axios.get(`${CONS.open}/states`, {
      headers: {
        'agency': agencyName
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getCityByStateData(id) {
  try {
    const res = await axios.get(`${CONS.open}/state/${id}`, {
      headers: {
        'agency': agencyName
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getAllDestinationData(){
  try {
    const res = await axios.get(`${CONS.open}/destination`, {
      headers: {
        'agency': agencyName
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getDataByTrackingData(track) {
	try {
		const res = await axios.get(`${CONS.open}/tracking/${track}`, {
      headers: {
        'agency': agencyName
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function makeRateDataOpen(jsn) {
  try {
    const res = await axios.post(`${CONS.open}/calculator`, jsn, {
      headers: {
        'agency': agencyName
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function makeRateDataDomesticOpen(jsn) {
  try {
    const res = await axios.post(`${CONS.open}/calculator/domestic`, jsn, {
      headers: {
        'agency': agencyName
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}