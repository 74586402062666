/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Header from "../../components/Headers/Header";
import { Card, CardBody, Container, Row, Spinner } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getAgencyData } from "./../../utils/token";
import { createPackageData, createPackageDomesticData, createPackageWarehousePickupData } from "./../../api/users";

export default function Success () {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    processPackage();
  }, []);
  
  const processPackage = async () => {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const type = params.get('type');

    if (code === null) {
      window.location.href = "/admin/services";
    }
    else if(type === "domestic") {
      const jsn = {
        "code": code
      }

      const res  = await createPackageDomesticData(jsn);
      
      if(res.success === true) {
        setError(false);
        setMessage("");
      }
      else {
        setError(true);
        setMessage(res.message);
      }

      setTimeout(() => {
        window.location.href = "/admin/purchase";
      }
      , 7000);
    }
    else if(type === "pickup") {
      const jsn = {
        "code": code
      }

      const res  = await createPackageWarehousePickupData(jsn);
      
      if(res.success === true) {
        setError(false);
        setMessage("");
      }
      else {
        setError(true);
        setMessage(res.message);
      }

      setTimeout(() => {
        window.location.href = "/admin/purchase";
      }
      , 7000);
    }
    else {
      const jsn = {
        "code": code
      }

      const res  = await createPackageData(jsn);
      
      if(res.success === true) {
        setError(false);
        setMessage("");
      }
      else {
        setError(true);
        setMessage(res.message);
      }

      setTimeout(() => {
        window.location.href = "/admin/purchase";
      }
      , 7000);

    }
    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <div className="text-center">
                  <ul style={{
                    listStyle: "none",
                    padding: "0px",
                    marginTop: "20px"
                  }}>
                    <li>
                      <img 
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "contain",
                        }}
                        alt="..."
                        src={getAgencyData("logo")} />
                    </li>
                    <li style={{
                      marginTop: "40px",
                    }}>
                      {
                        (error) ?
                          <h1> Ha ocurrido un error </h1> : 
                          <h1> Su pago ha sido procesado con exito </h1>
                      }
                    </li>
                    <li>
                      {
                        (loading) ? 
                        <>
                          <Spinner color="primary" />
                          <br />
                          <p> Espere mientras su paquete esta siendo procesado... </p>
                        </> : 
                        <> {
                          (error) ?
                            <p style={{
                              fontSize: "20px",
                            }}>
                              {message}
                            </p> : 
                            <p style={{
                              fontSize: "20px",
                            }}> 
                              Su paquete ha sido procesado con exito!
                              <br />
                              Sera redirigido a la pagina de rastreo en 5 segundos
                            </p>
                        }
                        </>
                      }
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
