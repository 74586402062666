/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "../../../components/Headers/Header";
import { Spinner, Col, Card, CardHeader, Table, Container, Row, Button, Input, InputGroupAddon, InputGroup, InputGroupText, CardBody } from "reactstrap";
import { getOrderStatusData } from '../../../api/admin';
import dayjs from 'dayjs';
import { getColor } from 'utils/token';

export default function Destiny() {
  const status_id = 4
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [totalRow, setTotalRow] = useState('50');
  const [page, setPage] = useState({currentPage: 1, page: 1, total: 0})

  useEffect(() => {
    getOrders();
  }, []);

  const handleSearch = (text) => {
    if (text) {
      const newData = data.filter( (item) => {
        const itemData = item.tracking_number
          ? item.tracking_number.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setDataSearch(newData);
    } 
    else {
      setSearch(text);
      setDataSearch(data);
    }   
  }

  const getOrders = async () => {
    setLoading(true);
    const res = await getOrderStatusData(totalRow, page.page, status_id);
  
    if(res.success === true) {
      setError(false);
      setPage({...page, total: Number(res.data.pages)});
      setData(res.data.data);
      setDataSearch(res.data.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const productType = (type) => {
    switch(type) {
      case "AIR":
        return 'Aereo';
      case "OCEAN":
        return 'Maritimo';
      default:
        return 'Error';
    }
  }

  const getMoreRowsBySelect = async (val) => {
    setLoading(true);
    const res = await getOrderStatusData(val, page.page, status_id);
 
    if(res.success === true) {
      setTotalRow(val);
      setError(false);
      setPage({...page, total: Number(res.data.pages)});
      setData(res.data.data);
      setDataSearch(res.data.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const lessPage = async () => {
    setLoading(true);
    const cp = page.currentPage;
    const newCp = cp - 1;

    if(cp > 0) {
      const res = await getOrderStatusData(totalRow, newCp, status_id)
      setPage({...page, currentPage: newCp});
      setData(res.data.data);
      setDataSearch(res.data.data);
    }
    setLoading(false);
  }

  const morePage = async () => {
    setLoading(true);
    const cp = page.currentPage;
    const newCp = cp + 1;

    if(cp < newCp) {
      const res = await getOrderStatusData(totalRow, newCp, status_id);
      setPage({...page, currentPage: newCp});
      setData(res.data.data);
      setDataSearch(res.data.data);
    }
    setLoading(false);
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Ordenes - En destino </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizonal'>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={search}
                      style={{paddingLeft: '5px', width: '65%'}}
                      placeholder={"Buscar"}
                      onChange={(val) =>  handleSearch(val.target.value)}
                      />

                    <select
                      className='form-control'
                      value={totalRow}
                      style={{
                        appearance: 'auto',
                        width: '150px'
                      }}
                      onChange={(val) => getMoreRowsBySelect(val.target.value)}
                    >
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </InputGroup>
                  <br />
                </div>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col"> Tracking </th>
                      <th scope="col"> Tipo </th>
                      <th scope="col"> Cliente </th>
                      <th scope="col"> Estado </th>
                      <th scope="col"> Origen </th>
                      <th scope="col"> Destino </th>
                      <th scope="col"> Fecha </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loading) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{color: '#004899'}} />
                        </td>
                      </tr>:
                      (error) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'>
                          <label> Ha ocurrido un error, por favor intente nuevamente. </label>
                          <br />
                          <Button 
                            color="secondary"
                            onClick={() => getOrders()}> Cargar </Button>
                        </td>
                      </tr> : 
                      (data.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> No hay información registrada. </td>
                      </tr>
                      :
                      dataSearch.map( (d,i) => (
                        <tr key={i} style={{
                          color: (d.payment_waring === 1) ? '#FF0000' : "#525f7f"
                        }}>
                          <td>
                            {++i}
                          </td>
                          <td>
                            {String(d.tracking_number).trim().toLocaleUpperCase()}
                          </td>
                          <td>
                            {productType(d.product_type)}
                          </td>
                          <td>
                            {String(d.client).trim()}
                          </td>
                          <td>
                            {String(d.status).trim()}
                          </td>
                          <td>
                            {String(d.package_sender).trim()}
                          </td>
                          <td>
                            {String(d.package_receive).trim()}
                          </td>
                          <td>
                            {dayjs(d.date).format("DD/MM/YYYY")}
                          </td>
                          <td className="text-right">
                            <Button 
                              size='sm'
                              style={{
                                backgroundColor: getColor(),
                                borderColor: getColor(),
                                color: '#fff'
                              }}
                              to={`/admin/orders/${d.id}`} 
                              tag={Link}
                            > Detalle </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>

                <div>
                  <ul id="ulArrow">
                    <li>
                      {
                        (page.currentPage === 1) ? <></> : <>
                          <i 
                            onClick={() => lessPage()}
                            className="arrowPage fas fa-chevron-left"></i>
                        </>
                      }
                    </li>
                    <li>
                      <span>{page.currentPage} / {page.total}</span>
                    </li>
                    <li>
                      {
                        (page.currentPage === page.total) ? <> </> : <>
                          <i 
                            onClick={() => morePage()}
                            className="arrowPage fas fa-chevron-right"></i>
                        </>
                      }
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

