/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Header from "../../../components/Headers/Header";
import {Spinner, Card, CardHeader, CardBody, Container, Row, Col, Button } from "reactstrap";
import dayjs from 'dayjs';
import { getColor } from "utils/token";
import { getDataByIdentifierData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";

export default function ViewOrder () {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ page: false, payBtn: false });
  const [status, setStatus] = useState(1);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    "id": "",
    "status": "",
    "status_id": "",
    "product_name": "",
    "package_number": "",
    "tracking_number": "",
    "price": "",
    "picture": "",
    "payment": "",
    "sender_name": "",
    "sender_email": "",
    "sender_phone": "",
    "sender_address": "",
    "sender_place": "",
    "receive_name": "",
    "receive_email": "",
    "receive_phone": "",
    "receive_address": "",
    "weight": "",
    "height": "",
    "width": "",
    "length": "",
    "content": "",
    "coupon": "",
    "discount": "",
    "final_payment": "",
    "ups_tracking": "",
    "date": "",
    "product_type": "",
    'air_eta': "",
    'ocean_eta': "",
    'process_date': "",
    'lot_tracking': ""
  });
  
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataByIdentifier();
  }, []);
  
  const getDataByIdentifier = async () => {
    setLoading({...loading, page: true});
    const res = await getDataByIdentifierData(id);

    if(res.success === true) {
      setError(false);
      setStatus(res.data.status_id);
      setData({
        "id": res.data.id,
        "status": res.data.status,
        "product_name": res.data.product_name,
        "package_number": res.data.package_number,
        "tracking_number": res.data.tracking_number,
        "price": res.data.price,
        "picture": res.data.picture,
        "payment": res.data.payment,
        "sender_name": res.data.sender_name,
        "sender_email": res.data.sender_email,
        "sender_phone": res.data.sender_phone,
        "sender_address": res.data.sender_address,
        "sender_place": res.data.sender_place,
        "receive_name": res.data.receive_name,
        "receive_email": res.data.receive_email,
        "receive_phone": res.data.receive_phone,
        "receive_address": res.data.receive_address,
        "weight": res.data.weight,
        "height": res.data.height,
        "width": res.data.width,
        "length": res.data.length,
        "content": res.data.content,
        "coupon": res.data.coupon,
        "discount": res.data.discount,
        "final_payment": res.data.final_payment,
        "ups_tracking": res.data.ups_tracking,
        "date": dayjs(res.data.date).add(1, 'day').format("DD/MM/YYYY"),
        "product_type": res.data.product_type,
        'air_eta': res.data.air_eta,
        'ocean_eta': res.data.ocean_eta,
        'process_date': res.data.process_date,
        'lot_tracking': res.data.lot_tracking,
        'ups_prn': res.data.ups_prn
      });
    }
    else {
      setError(true);
      setData({});
    }
    setLoading({...loading, page: false});
  }

  const getProductType = () => {
    switch (data.product_type) {
      case "OCEAN":
        return "Marítimo";
      case "AIR":
        return "Aéreo";
      case "LAND":
        return "Terrestre";
      default:
        return "No definido";
    }
  }

  const getWeightKg = (value) => {
    return (value * 0.45359237).toFixed(2);
  }
  
  const getMeasurament = (type, height, length, width) => {
    if(type === "OCEAN") {
      return {
        "type": "Pies cúbicos",
        "value": ((height * length * width) / 1728).toFixed(2)
      }
    }
    else {
      return {
        "type": "Peso volumen",
        "value": ((height * length * width) / 166).toFixed(2)
      }
    }
  }

  const getETA = (type) => {
    if(type === "OCEAN") {
      return dayjs(data.process_date).add(data.ocean_eta, 'days').format("DD/MM/YYYY");
    }
    else {
      return dayjs(data.process_date).add(data.air_eta, 'days').format("DD/MM/YYYY");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card 
              style={{
                border:  (data.payment === 1) ? "3px solid #f5365c" : ""
              }}
            >
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <ul style={{
                      padding: "0px",
                      listStyle: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "0"
                    }}>
                      <li>
                        <h3> VTS Tracking: #{String(data.tracking_number).toUpperCase()} </h3>
                      </li>
                      <li>
                        {
                          (data.lot_tracking !== null) ? 
                          <h3> Contenedor: #{String(data.lot_tracking).toUpperCase()} </h3> : 
                          <></>
                        }
                      </li>
                    </ul>
                    <h3> UPS Tracking: {String(data.ups_tracking).toUpperCase()} </h3>
                  </div>
                  {
                    (data.payment === 1) ? <> 
                      <div 
                        style={{
                          textAlign: "right",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end"
                        }}
                        className="col mb-0 float-rigth">
                          <span style={{
                            color: "#f5365c",
                          }}>
                            Este paquete tiene un pago pendiente para ser liberado
                          </span>
                        <i className="fas fa-exclamation-triangle zoom-in-zoom-out" />
                      </div>
                    </> : <></>
                  }
                </Row>
              </CardHeader>
                
              <CardBody>
                <div className='paddingHorizontal'>
                  {
                    (loading.page === true) ? <>
                      <div className="text-center">
                        <Spinner 
                          style={{
                            color: getColor
                          }}
                        />
                      </div>
                    </> : <>
                      {
                        (error === true) ? <>
                          <div className="text-center">
                            <h3> No se encontraron resultados </h3>
                          </div>
                        </> : <>
                          <Row style={{
                            marginBottom: "20px"
                          }}>
                            <Col>
                              <ul style={{
                                padding: "0px",
                                listStyle: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly"
                              }}>
                                <li>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                  }}>
                                    <img 
                                      alt='label'
                                      style={{
                                        marginBottom: "5px",
                                        width: "40px", 
                                        height: "40px", 
                                        objectFit: "contain" 
                                      }}
                                      src = {require("./../../../assets/img/label.png").default} />
                                    <div 
                                      style={{ 
                                        backgroundColor: (status >= 1) ? getColor() : "#8C8C8C", 
                                        width: "10px", 
                                        height: "10px"
                                      }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                    </div>
                                    <span style={{fontSize: "14px"}}> Etiqueta creada </span>
                                  </div>
                                </li>

                                <li>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                  }}>
                                    <img 
                                      alt='warehouse'
                                      style={{ 
                                        marginBottom: "5px",
                                        width: "40px", 
                                        height: "40px", 
                                        objectFit: "contain" 
                                      }}
                                      src = {require("./../../../assets/img/warehouse.png").default} />
                                    <div 
                                      style={{ 
                                        backgroundColor: (status >= 2) ? getColor() : "#8C8C8C", 
                                        width: "10px", 
                                        height: "10px"
                                      }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                    </div>
                                    <span style={{fontSize: "14px"}}> En almacen </span>
                                  </div>
                                </li>

                                <li>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                  }}>
                                    <img 
                                      alt='transit'
                                      style={{
                                        marginBottom: "5px",
                                        width: "40px", 
                                        height: "40px", 
                                        objectFit: "contain" 
                                      }}
                                      src = {require("./../../../assets/img/transit.png").default} />
                                    <div 
                                      style={{ 
                                        backgroundColor: (status >= 3) ? getColor() : "#8C8C8C", 
                                        width: "10px", 
                                        height: "10px"
                                      }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                    </div>
                                    <span style={{fontSize: "14px"}}> Enviado / transito </span>
                                  </div>
                                </li>

                                <li>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                  }}>
                                    <img 
                                      alt='onsite'
                                      style={{
                                        marginBottom: "5px",
                                        width: "40px", 
                                        height: "40px", 
                                        objectFit: "contain" 
                                      }}
                                      src = {require("./../../../assets/img/onsite.png").default} />
                                    <div 
                                      style={{ 
                                        backgroundColor: (status >= 4) ? getColor() : "#8C8C8C", 
                                        width: "20px", 
                                        height: "20px"
                                      }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                    </div>
                                    <span style={{fontSize: "14px"}}> En destino </span>
                                  </div>
                                </li>

                                <li>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                  }}>
                                    <img 
                                      alt='delivery'
                                      style={{
                                        marginBottom: "5px",
                                        width: "40px", 
                                        height: "40px", 
                                        objectFit: "contain" 
                                      }}
                                      src = {require("./../../../assets/img/deliver.png").default} />
                                    <div 
                                      style={{ 
                                        backgroundColor: (status >= 5) ? getColor() : "#8C8C8C", 
                                        width: "20px", 
                                        height: "20px"
                                      }}
                                      className="icon icon-shape text-white rounded-circle shadow">
                                    </div>
                                    <span style={{fontSize: "14px"}}> Entregado </span>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{textAlign: "center"}}>
                              <img 
                                style={{
                                  width: '200px',
                                }}
                                alt="label"
                                src={data.picture} />
                            </Col>

                            <Col>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                              }}>
                                <li>
                                  <h2> {data.product_name} </h2>
                                </li>
                                <li>
                                  <label> <b>✅ </b> { data.status } </label>
                                  <a 
                                    style={{
                                      color: "#5e72e4",
                                      fontSize: "14px",
                                      textDecoration: "underline",
                                      display: "block",
                                      marginBottom: "10px"
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://es-us.ups.com/track?loc=es_US&&tracknum=${data.ups_tracking}`}>Rastrear en UPS </a>
                                </li>
                                <li>
                                  {
                                    (data.final_payment !== null) ? <>
                                    <li>
                                      <label> <b>💵 Pago total: </b> ${ (Number(data.price) + Number(data.final_payment)).toFixed(2) }</label>
                                    </li>
                                    </> : <></>
                                  }
                                  <label> 
                                    <b>
                                      {
                                        (data.final_payment === null) ? "💵 Pago: " : "💵 Primer pago: "
                                      }
                                    </b>
                                    ${Number(data.price).toFixed(2)}
                                  </label>
                                </li>
                                {
                                  (data.final_payment !== null) ? <>
                                    <li>
                                      <label> 
                                        <b style={{
                                          color: (data.payment === 1) ? "#f5365c" : "#525f7f"
                                        }}>
                                          {
                                            (data.payment === 1) ? "💵 Pago pendiente: " : "💵 Segundo pago: "
                                          } 
                                        </b>
                                        ${Number(data.final_payment).toFixed(2)}
                                      </label>
                                    </li>
                                  </> : <></>
                                }
                                <li>
                                  <label> <b>📅 Compra: </b> {data.date} </label>
                                </li>
                                {
                                 (data.process_date !== null) ? <>
                                    <li>
                                      <label> <b>📆 ETA: </b> {getETA(data.product_type)}</label>
                                    </li>
                                  </> : <></>
                                }
                                <li>
                                  <label> <b>🚚 Pickup: </b> 
                                    {
                                      (data.ups_prn === null) ? "No" : `Si (${data.ups_prn})`
                                    }
                                  </label>
                                </li>
                              </ul>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="4">
                              <h3> 📤 Remitente </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  {data.sender_name}
                                </li>
                                <li> 
                                  📱 {data.sender_phone}
                                </li>
                                <li> 
                                  ✉ {data.sender_email}
                                </li>
                                <li> 
                                  <b>Direccion: </b> {data.sender_address}
                                </li>
                                <li> 
                                  {data.sender_place}
                                </li>
                              </ul>
                            </Col>
                            
                            <Col lg="4">
                              <h3> 📥 Consignatario </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  {data.receive_name}
                                </li>
                                <li> 
                                  📱 {data.receive_phone}
                                </li>
                                <li> 
                                  ✉ {data.receive_email}
                                </li>
                                <li> 
                                <b>Direccion: </b> {data.receive_address}
                                </li>
                              </ul>
                            </Col>

                            <Col lg="4">
                              <h3> 📦 Datos del Paquete </h3>
                              <ul style={{
                                listStyle: 'none',
                                padding: '0px',
                                lineHeight: '2',
                              }}>
                                <li> 
                                  <b> Servicio: </b> { getProductType(data.product_type) }
                                </li>
                                {
                                  (data.weight !== null) ? <>
                                    <li> 
                                      <b> Dimensiones: </b> {data.height} x {data.width} x {data.length} pulgadas
                                    </li>
                                  </> : <></>
                                }
                                {
                                  (data.weight !== null) ? <>
                                    <li>
                                      <b> Peso: </b> {data.weight}lbs - {getWeightKg(data.weight)}kg
                                    </li>
                                  </> : <></>
                                }
                                <li>
                                  <b> {getMeasurament(data.product_type, data.height, data.length, data.width).type}: </b>
                                    {getMeasurament(data.product_type, data.height, data.length, data.width).value}
                                </li>
                                <li> 
                                  <b> Contenido: </b> {data.content}
                                </li>
                              </ul>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12" >
                              <br />
                              <div className="float-right">
                                <Button color="gray" to="/admin/orders" tag={Link}> Regresar </Button>
                              </div>
                            </Col>
                          </Row>
                        </>
                      }
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
