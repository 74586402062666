/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal,ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getProductData, updateCouponData, getCouponByIdData, deleteCouponData } from "../../../api/admin";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';

export default function EditCoupon() {
  const { id } = useParams();
  const notificationAlertRef = React.useRef(null);
  const [arrProduct, setArrProduct] = useState([]);
  const [code, setCode] = useState('');
  const [discount, setDiscount] = useState("");
  const [oneTime, setOneTime] = useState("");
  const [quantity, setQuantity] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [product, setProduct] = useState("");
  const [active, setActive] = useState('0');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState({
    page: true,
    button: false
  });

  useEffect(() => {
    getCouponById();
    getProduct();
  }, []);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getCouponById = async () => {
    setLoading({...loading, page: true});

    const res = await getCouponByIdData(id);

    if(res.success === true) {
      setCode(res.data.code)
      setDiscount(res.data.discount)
      setOneTime(res.data.one_time)
      setQuantity(res.data.quantity)
      setExpirationDate(res.data.expiration_date)
      setProduct(res.data.product_id)
      setActive(res.data.active)
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos del cupón");
    }

    setLoading({...loading, page: false});
  }

  const getProduct = async () => {
    const data = await getProductData();

    if(data.success === true) {
      setArrProduct(data.data);
    }
    else {
      setArrProduct([]);
    }

  }

  const addNewProduct = async () => {
    setLoading({...loading, button: true});

    if(String(code).trim() === "") {
      notifyAlert("danger", "Error", "El nombre no puede estar vacío");
    }
    else if(String(discount).trim() === "") {
      notifyAlert("danger", "Error", "El descuento no puede estar vacío");
    }
    else if(String(product).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el producto");
    }
    else {
      const jsn = {
        "id": id,
        "code": code,
        "discount": discount,
        "quantity": quantity,
        "expirationDate": expirationDate,
        "product": product,
        "active": active,
        "oneTime": oneTime
      }

      const res = await updateCouponData(jsn);

      if(res.success === true) {
        setCode("");
        setDiscount("");
        setQuantity("");
        setExpirationDate("");
        setProduct("");
        setActive('0');
        notifyAlert("success", "Éxito", "El cupón ha sido actualizado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al actualizar el cupón");
      }
    }

    setLoading({...loading, button: false});
  }

  const deleteCoupon = async () =>  {
    const res = await deleteCouponData(id);
    
    if(res.success === true) {
      window.location.href = '/admin/coupon'
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al eliminar el cupón");
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Cupón </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    (loading.page === true) ?
                    <>
                      <div className="text-center">
                        <Spinner style={{
                          color: getColor()
                        }} />
                      </div>
                    </> :
                    <>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Codigo </label>
                            <Input
                              value={code}
                              className="form-control"
                              onChange={val => setCode(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Descuento </label>
                            <Input
                              type='number'
                              min='1'
                              max='100'
                              value={discount}
                              className="form-control"
                              onChange={val => setDiscount(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Usar solo una vez </label>
                            <select
                              style={{
                                appearance: 'auto'
                              }}
                              className="form-control"
                              value={oneTime}
                              onChange={val => setOneTime(val.target.value)}
                            >
                              <option value=""> Seleccionar </option>
                              <option value="0"> No </option>
                              <option value="1"> Si </option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Cantidad </label>
                            <Input
                              type='number'
                              min='0'
                              value={quantity}
                              className="form-control"
                              onChange={val => setQuantity(val.target.value)}/>
                            <span style={{
                              color: "gray",
                              fontSize: "12px"
                            }}> En caso de no indicar la cantidad, el cupón no tendra limite.</span>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Fecha de expiración </label>
                            <Input
                              type='date'
                              value={expirationDate}
                              className="form-control"
                              onChange={val => setExpirationDate(val.target.value)}/>
                            <span style={{
                              color: "gray",
                              fontSize: "12px"
                            }}> En caso de no indicar la fecha de expiración, el cupón no tendra limite de tiempo.</span>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Productos </label>
                            <select
                              style={{
                                appearance: 'auto'
                              }}
                              className="form-control"
                              value={product}
                              onChange={val => setProduct(val.target.value)}
                            >
                              <option value=""> Seleccionar </option>
                              {
                                arrProduct.map((item, key) => {
                                  return (
                                    <option key={key} value={item.id}> {item.sku} - {item.name} </option>
                                  )
                                })
                              }
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> Activo </label>
                            <select
                              style={{
                                appearance: 'auto'
                              }}
                              className="form-control"
                              value={active}
                              onChange={val => setActive(val.target.value)}
                            >
                              <option value="1"> Si </option>
                              <option value="0"> No </option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" >
                          <br />
                          <div className="float-right">
                            {
                              (loading.button) ? <Spinner style={{color: getColor()}}/> :
                              <div>
                                <Button color="gray" to="/admin/coupon" tag={Link}> Regresar </Button>
                                <Button
                                  color='danger'
                                  onClick={() => setModal(!modal)}
                                  > Eliminar
                                </Button>
                                <Button
                                  style={{
                                    backgroundColor: getColor(),
                                    color: '#fff',
                                    border: 0
                                  }}
                                  onClick={() => addNewProduct()}
                                  > Guardar
                                </Button>
                              </div>
                            } 
                          </div>
                        </Col>
                      </Row>
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal()}> Mensaje </ModalHeader>
          <ModalBody>
            ¿Está seguro que desea eliminar el cupón?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!modal)}> Cancelar </Button>
            <Button color="danger" onClick={() => deleteCoupon()}> Eliminar </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}

