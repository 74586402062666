/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from "reactstrap";
import { AuthContext } from "./../../context/AuthContext";
import { useEffect } from "react";
import { getTokenData } from "./../../utils/token";

function Sidebar({ toggleSidenav, sidenavOpen, logo }) {
  const { signOut } = React.useContext(AuthContext);
  const [agency, setAgency] = useState({});
  const [orderToggle, setOrderToggle] = useState(false);
  const [pkgToggle, setPkgToggle] = useState(false);
  const [addressToggle, setAddressToggle] = useState(false);
  const [admin, setAdmin] = useState(0);
  const [role, setRole] = useState(0);

  const location = useLocation();

  useEffect(() => {
    const customizeData = JSON.parse(localStorage.getItem("agency"));
    setAgency({
      name: customizeData.name,
      logo: customizeData.logo,
      color: customizeData.color,
      role: customizeData.role
    });

    const tokenData = getTokenData("vboxToken", "a");
    const roleData = getTokenData("vboxToken", "role");
    setAdmin(tokenData);
    setRole(roleData);
  }, []);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  }
  else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  
  return (
    <Navbar
      className={"sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left"}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      <PerfectScrollbar>
        <div className="scrollbar-inner">
          <div className="sidenav-header d-flex align-items-center">
            {logo ? (
              <NavbarBrand {...navbarBrandProps}>
                <img
                  alt={agency.name}
                  className="navbar-brand-img"
                  src={agency.logo}
                />
              </NavbarBrand>
            ) : null}
            <div className="ml-auto">
              <div
                className={classnames("sidenav-toggler d-none d-xl-block", {
                  active: sidenavOpen,
                })}
                onClick={toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </div>
          </div>

          <div className="navbar-inner">
            <Collapse navbar isOpen={false}>
              <h6 className="navbar-heading p-0 text-muted">
                <span className="docs-normal"> Menu </span>
                <span className="docs-mini">
                  <i className="fas fa-list" />
                </span>
              </h6>

              {
                (admin === 1) ? <>
                  <Nav navbar>
                    {
                      ([1, 2].includes(role)) ? <>
                        <NavItem>
                          <NavLink
                            data-toggle="collapse"
                            aria-expanded={orderToggle}
                            onClick={() => {
                              setOrderToggle(!orderToggle)
                            }}
                          >
                            <i className="fas fa-list-ul"></i>
                            <span className="nav-link-text">Ordenes</span>
                          </NavLink>
    
                          <Collapse isOpen={orderToggle}>
                            <Nav className="nav-sm flex-column">
                              <NavItem className={activeRoute(`/admin/orders`)}>
                                <NavLink
                                  to="/admin/orders"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> Internacional </span>
                                </NavLink>
                              </NavItem>

                              <NavItem className={activeRoute(`/admin/orders/domestic`)}>
                                <NavLink
                                  to="/admin/orders/domestic"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> Domestico </span>
                                </NavLink>
                              </NavItem>
    
                              <NavItem className={activeRoute(`/admin/orders/search`)}>
                                <NavLink
                                  to="/admin/search"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> Buscar paquetes </span>
                                </NavLink>
                              </NavItem>
    
                              <NavItem className={activeRoute(`/admin/orders/process`)}>
                                <NavLink
                                  to="/admin/process"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> Recibir paquetes </span>
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Collapse>
                        </NavItem>
                      
                        <NavItem>
                          <NavLink
                            data-toggle="collapse"
                            aria-expanded={pkgToggle}
                            onClick={() => {
                              setPkgToggle(!pkgToggle)
                            }}
                          >
                            <i className="fas fa-traffic-light"></i>
                            <span className="nav-link-text">Paquetes</span>
                          </NavLink>
    
                          <Collapse isOpen={pkgToggle}>
                            <Nav className="nav-sm flex-column">
                              <NavItem className={activeRoute(`/admin/packages/label`)}>
                                <NavLink
                                  to="/admin/packages/label"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i class="far fa-circle" />
                                  </span>
                                  <span className="sidenav-normal"> Etiqueta creada </span>
                                </NavLink>
                              </NavItem>

                              <NavItem className={activeRoute(`/admin/packages/warehouse`)}>
                                <NavLink
                                  to="/admin/packages/warehouse"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> En Almacen </span>
                                </NavLink>
                              </NavItem>

                              <NavItem className={activeRoute(`/admin/packages/transit`)}>
                                <NavLink
                                  to="/admin/packages/transit"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> Enviado / Transito </span>
                                </NavLink>
                              </NavItem>

                              <NavItem className={activeRoute(`/admin/packages/destiny`)}>
                                <NavLink
                                  to="/admin/packages/destiny"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> En destino </span>
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Collapse>
                        </NavItem>
                      
                        <NavItem className={activeRoute(`/admin/lots`)}>
                          <NavLink
                            to="/admin/lots"
                            tag={Link}>
                              <i className="fas fa-clipboard-list"></i>
                            <span className="nav-link-text"> Lotes </span>
                          </NavLink>
                        </NavItem>
                        
                        <NavItem className={activeRoute(`/admin/calculate`)}>
                          <NavLink
                            to="/admin/calculate"
                            tag={Link}>
                              <i className="fas fa-calculator"></i>
                            <span className="nav-link-text"> Calculadora </span>
                          </NavLink>
                        </NavItem>

                      </> : <></>
                    }

                    {
                      ([1].includes(role)) ? <>
                        <NavItem className={activeRoute(`/admin/graph`)}>
                          <NavLink
                            to="/admin/graph"
                            tag={Link}>
                              <i className="fas fa-chart-bar"></i>
                            <span className="nav-link-text"> Graficas </span>
                          </NavLink>
                        </NavItem>
                        
                        <NavItem className={activeRoute(`/admin/products`)}>
                          <NavLink
                            to="/admin/products"
                            tag={Link}>
                              <i className="fas fa-boxes"></i>
                            <span className="nav-link-text"> Productos </span>
                          </NavLink>
                        </NavItem>
                      </> : <></>
                    }

                    {
                      ([1,2].includes(role)) ? <>
                        <NavItem className={activeRoute(`/admin/technical-support`)}>
                          <NavLink
                            to="/admin/technical-support"
                            tag={Link}>
                              <i className="fas fa-headset"></i>
                            <span className="nav-link-text"> Soporte técnico </span>
                          </NavLink>
                        </NavItem>
                      </> : <></>
                    }

                    {
                      ([1].includes(role)) ? <>
                        <NavItem className={activeRoute(`/admin/clients`)}>
                          <NavLink
                            to="/admin/clients"
                            tag={Link}>
                              <i className="fas fa-users"></i>
                            <span className="nav-link-text"> Clientes </span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            data-toggle="collapse"
                            aria-expanded={addressToggle}
                            onClick={() => {
                              setAddressToggle(!addressToggle)
                            }}
                          >
                            <i className="fas fa-globe-americas"></i>
                            <span className="nav-link-text"> Direcciones </span>
                          </NavLink>

                          <Collapse isOpen={addressToggle}>
                            <Nav className="nav-sm flex-column">
                              <NavItem className={activeRoute(`/admin/address/state`)}>
                                <NavLink
                                  to="/admin/address/state"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> Estados </span>
                                </NavLink>
                              </NavItem>

                              <NavItem className={activeRoute(`/admin/address/city`)}>
                                <NavLink
                                  to="/admin/address/city"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="far fa-circle"></i>
                                  </span>
                                  <span className="sidenav-normal"> Ciudad </span>
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Collapse>
                        </NavItem>
                      </> : <></>
                    }
                    
                    {
                      ([1, 2].includes(role)) ? <>
                        <NavItem className={activeRoute(`/admin/transports`)}>
                          <NavLink
                            to="/admin/transports"
                            tag={Link}>
                              <i className="fas fa-people-carry"></i>
                            <span className="nav-link-text"> Transportistas </span>
                          </NavLink>
                        </NavItem>
                      </> : <></>
                    }

                    {
                      ([1].includes(role)) ? <>
                        <NavItem className={activeRoute(`/admin/users`)}>
                          <NavLink
                            to="/admin/users"
                            tag={Link}>
                              <i className="fas fa-user-friends"></i>
                            <span className="nav-link-text"> Usuarios </span>
                          </NavLink>
                        </NavItem>

                        <NavItem className={activeRoute(`/admin/settings`)}>
                          <NavLink
                            to="/admin/settings"
                            tag={Link}>
                              <i className="fas fa-cogs"></i>
                            <span className="nav-link-text"> Configuraciones </span>
                          </NavLink>
                        </NavItem>
                      </> : <></>
                    }

                    {
                      /*
                        <NavItem className={activeRoute(`/admin/coupon`)}>
                          <NavLink
                            to="/admin/coupon"
                            tag={Link}>
                              <i className="fas fa-ticket-alt"></i>
                            <span className="nav-link-text"> Cupón </span>
                          </NavLink>
                        </NavItem>
                      */
                    }

                  </Nav>
                </> : 
                <>
                  {/* */}
                  <Nav navbar>
                    <NavItem className={activeRoute(`/admin/agency`)}>
                      <NavLink
                        to="/admin/agency"
                        tag={Link}>
                        <i className="fas fa-truck-loading"></i>
                        <span className="nav-link-text"> { agency.name } </span>
                      </NavLink>
                    </NavItem>

                    <NavItem className={activeRoute(`/admin/services`)}>
                      <NavLink
                        to="/admin/services"
                        tag={Link}>
                        <i className="fas fa-store"></i>
                        <span className="nav-link-text">Servicios</span>
                      </NavLink>
                    </NavItem>

                    <NavItem className={activeRoute(`/admin/purchase`)}>
                      <NavLink
                        to="/admin/purchase"
                        tag={Link}>
                        <i className="fas fa-boxes"></i>
                        <span className="nav-link-text"> Mis compras </span>
                      </NavLink>
                    </NavItem>

                    <NavItem className={activeRoute(`/admin/tracking`)}>
                      <NavLink
                        to="/admin/tracking"
                        tag={Link}>
                        <i className="fas fa-barcode"></i>
                        <span className="nav-link-text"> Rastrea tu paquete </span>
                      </NavLink>
                    </NavItem>

                    <NavItem className={activeRoute(`/admin/calculator`)}>
                      <NavLink
                        to="/admin/calculator"
                        tag={Link}>
                        <i className="fas fa-calculator"></i>
                        <span className="nav-link-text"> Calcular tu envío</span>
                      </NavLink>
                    </NavItem>


                    <NavItem className={activeRoute(`/admin/support`)}>
                      <NavLink
                        to="/admin/support"
                        tag={Link}>
                        <i className="fas fa-headset"></i>
                        <span className="nav-link-text"> Soporte técnico </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </>
              }

              <hr className="my-3" />
              <h6 className="navbar-heading p-0 text-muted">
                <span className="docs-normal">CONFIGURACIONES</span>
                <span className="docs-mini">
                  <i className="fas fa-cogs" />
                </span>
              </h6>

              <Nav className="mb-md-3" navbar>
              
              {
                (admin === 1) ? <>
                  <NavItem className={activeRoute(`/admin/profile/admin`)}>
                    <NavLink
                      to="/admin/profile/admin"
                      tag={Link}>
                      <i className="ni ni-palette" />
                      <span className="nav-link-text">Perfil</span>
                    </NavLink>
                  </NavItem>
                </> : 
                <>
                  <NavItem className={activeRoute(`/admin/profile`)}>
                    <NavLink
                      to="/admin/profile"
                      tag={Link}>
                      <i className="ni ni-palette" />
                      <span className="nav-link-text">Perfil</span>
                    </NavLink>
                  </NavItem>
                </>
                }
                <NavItem style={{cursor: 'pointer'}}>
                  <NavLink
                    onClick={() => signOut()}>
                    <i className="fas fa-sign-out-alt" />
                    <span className="nav-link-text">Cerrar sesión</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </div>
      </PerfectScrollbar>
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
};

Sidebar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  })
};

export default Sidebar;
