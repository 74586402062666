/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Table } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { getTransportsData, getLotByTypeData, setNewLotData } from "../../../api/admin";
import { getAllStateData, getCityByStateData } from "../../../api/users";
import NotificationAlert from "react-notification-alert";
import { getColor } from 'utils/token';

export default function NewLots() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({
    transport: false,
    data: false,
    btn: false,
  });
  const [arrTransport, setArrTransport] = useState([]);
  const [arrPackages, setArrPackages] = useState([]);
  const [type, setType] = useState('');
  const [transport, setTransport] = useState('');
  const [packages, setPackages] = useState('');
  const [arrState, setArrState] = useState([]);
  const [arrCity, setArrCity] = useState([]);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  useEffect(async () => { 
    await getTransports();
    await getAllStateByAgency();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getTransports = async () => {
    setLoading({...loading, transport: true});
    
    const res = await getTransportsData();

    if(res.success === true) {
      setArrTransport(res.data);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos");
    }

    setLoading({...loading, transport: false});
  }

  const getDataByType = async (type) => {
    setLoading({...loading, data: true});
    
    const res = await getLotByTypeData(type);

    if(res.success === true) {
      setArrPackages(res.data.packages);
    }
    else {
      notifyAlert("danger", "Error", "Ha ocurrido un error al obtener los datos");
    }

    setType(type);
    setLoading({...loading, data: false});
  }

  const setPackageToSend = (value) => {
    const arr = [...packages];
    arr.includes(value) ? arr.splice(arr.indexOf(value), 1) : arr.push(value);
    setPackages(arr);
  }

  const getAllStateByAgency = async () => {
    setLoading({ ...loading, state: true });
    const res = await getAllStateData();
    if(res.success === true) {
      setArrState(res.data);
    }
    else {
      setArrState([]);
    }
    setLoading({ ...loading, state: false });
  }

  const getCityByState = async (val) => {
    setLoading({ ...loading, city: true });

    setState(val);
    const res = await getCityByStateData(val);

    if(res.success === true) {
      setArrCity(res.data);
    }
    else {
      setArrCity([]);
    }

    setLoading({ ...loading, city: false});
  }

  const addNewData = async () => {
    setLoading({...loading, btn: true});
    
    if(String(type).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el tipo de envío");
    }
    else if(String(transport).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el transportista");
    }
    else if(String(state).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar el estado de destino");
    }
    else if(String(city).trim() === "") {
      notifyAlert("danger", "Error", "Debe seleccionar la ciudad de destino");
    }
    else if(packages.length === 0) {
      notifyAlert("danger", "Error", "Debe seleccionar al menos un paquete");
    }
    else {
      const jsn = {
        "type": type,
        "transport": transport,
        "state": state,
        "city": city,
        "packages": packages
      }

      const res = await setNewLotData(jsn);

      if(res.success === true) {
        setTransport("");
        setType("");
        getTransports();
        setState("");
        setCity("");
        setPackages([]);
        notifyAlert("success", "Éxito", "El lote se ha creado correctamente");
      }
      else {
        notifyAlert("danger", "Error", "Ha ocurrido un error al crear el lote");
      }
    }

    setLoading({...loading, btn: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Lotes </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="3">
                      <FormGroup>
                        <label className="form-control-label"> Transportista </label>
                        {
                          (loading.transport) ? <>
                            <br />
                            <Spinner style={{ color: getColor() }} size="sm" />
                          </> : <>
                            <select
                              style={{
                                appearance: 'auto'
                              }}
                              className="form-control"
                              value={transport}
                              onChange={val => setTransport(val.target.value)}
                            >
                              <option value=""> Seleccionar </option>
                              {
                                arrTransport.map((item, key) => {
                                  return (
                                    <option value={item.id} key={key}> {item.name} </option>
                                  )
                                })
                              }
                            </select>
                          </>
                        }
                      </FormGroup>
                    </Col>

                    <Col lg="3">
                      <FormGroup>
                        <label className="form-control-label"> Tipo de Envío </label>
                        <select
                          style={{
                            appearance: 'auto'
                          }}
                          className="form-control"
                          value={type}
                          onChange={val => getDataByType(val.target.value)}
                        >
                          <option value=""> Seleccionar </option>
                          <option value="1"> Maritimo </option>
                          <option value="2"> Aereo </option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label">Seleccione Estado</label>
                        <select
                          className="form-control"
                            value={state}
                            onChange={(e) => getCityByState(e.target.value)}
                          >
                          <option value=""> Seleccionar </option>
                          {
                            (loading.state) ? <>
                              <Spinner size='sm' style={{ color: getColor() }}/>
                            </> : <>
                              {
                                arrState.map((item, key) => (
                                  <option key={key} value={item.id}> {item.name} </option>
                                ))
                              }
                            </>
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label">Seleccione Ciudad</label>
                        <select
                          value={city}
                          className="form-control"
                          onChange={(e) => setCity(e.target.value)}>
                          <option value=""> Seleccionar </option>
                          {
                            (loading.city) ? <>
                              <Spinner size='sm' style={{ color: getColor() }}/>
                            </> : <>
                              {
                                arrCity.map((item, key) => (
                                  <option key={key} value={item.id}> {item.name} </option>
                                ))
                              }
                            </>
                          }
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col>
                      <label className="form-control-label"> Paquetes para procesar </label>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Table>
                        <thead>
                          <tr>
                            <th> </th>
                            <th> Tracking </th>
                            <th> Tipo </th>
                            <th> Cliente </th>
                            <th> Destino </th>
                            <th> Seleccionar </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (loading.data) ? <>
                              <tr>
                                <td colSpan="12" className="text-center">
                                  <Spinner style={{ color: getColor() }} size="sm" />
                                </td>
                              </tr>
                            </> : <>
                            {
                              (arrPackages.length === 0) ? <>
                                <tr>
                                  <td colSpan="12" className="text-center">
                                    <h3> No hay paquetes para procesar </h3>
                                  </td>
                                </tr>
                              </> : <>
                                {
                                  arrPackages.map((item, key) => (
                                      <tr key={key}>
                                        <td> {++key} </td>
                                        <td> {String(item.tracking_number).toLocaleUpperCase()} </td>
                                        <td> {item.product_type} </td>
                                        <td> {item.client} </td>
                                        <td> {item.address} </td>
                                        <td>
                                          <FormGroup check>
                                            <label className="form-control-label">
                                              <Input
                                                type="checkbox"
                                                checked={packages.includes(item.id)}
                                                onChange={() => setPackageToSend(item.id)}
                                              />
                                            </label>
                                          </FormGroup>
                                        </td>
                                      </tr>
                                  ))
                                }
                              </>
                            }
                            </>
                          }
                        </tbody>
                      </Table>
                    </Col> 
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading.btn) ? <Spinner style={{color: getColor()}}/> :
                          <div>
                            <Button color="gray" to="/admin/lots" tag={Link}> Regresar </Button>
                            <Button
                              style={{
                                backgroundColor: getColor(),
                                color: '#fff',
                                border: 0
                              }}
                              onClick={() => addNewData()}
                              > Guardar
                            </Button>
                          </div>
                        } 
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

