/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/Headers/Header";
import { Spinner, Card, CardHeader, CardBody, Container, Row, Col,  Button } from "reactstrap";
import { getAllPurchaseData, cancelPickUpData, downloadLabelData } from '../../api/purchase';
import { getColor } from '../../utils/token';
import dayjs from 'dayjs';
import NotificationAlert from "react-notification-alert";

export default function Purchase() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({
    page: false,
    btnDownload: false,
    btnCancel: false
  });
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllPurchase();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  const getAllPurchase = async () => {
    setLoading({ ...loading, page: true });
    const res = await getAllPurchaseData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
    }
    else {
      setError(true);
      setData([]);
    }

    setLoading({ ...loading, page: false });
  }

  const cancelPickUp = async (id) => {
    setLoading({...loading, btnCancel: true});

    const res = await cancelPickUpData(id);

    if(res.success === true) {
      getAllPurchase();
    }
    else {
      notifyAlert("danger", "Mensaje", "Ha ocurrido un error al cancelar el servicio, por favor intente de nuevo mas tarde.");
    }

    setLoading({...loading, btnCancel: false}); 
  }

  const downloadLabel = async (id, tracking) => {
    setLoading({...loading, btnDownload: true});

    const res = await downloadLabelData(id);

    if(res.status === 200) {
      var link = document.createElement('a');
      link.download = `UPSlabel-${tracking}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
    }
    else {
      notifyAlert("danger", "Mensaje", "Ha ocurrido un error al descargar la etiqueta, por favor intente de nuevo mas tarde.");
    }

    setLoading({...loading, btnDownload: false});
  }
  
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> Mis compras </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizonal'>
                  {
                    (loading.page) ? <>
                      <div style={{textAlign: "center"}}>
                        <Spinner style={{color:getColor()}} />
                      </div>
                    </> : <>
                    {
                      (error) ? <>
                        <div style={{textAlign: "center"}}>
                          <h3> Ha ocurrido un error al traer las compras, por favor intente de nuevo mas tarde.</h3>
                        </div>
                      </> : <>
                      <div>
                        {
                          (data.length === 0) ? <>
                            <div style={{textAlign: "center"}}>
                              <h3 style={{ color: "gray" }}> No hay compras registradas. </h3>
                            </div>
                          </> : <>
                            {
                              data.map((p, key) => (
                                <Card key={key}
                                  style={{
                                    border: (p.payment === 1) ? "2px solid #f5365c" : "1px solid #e8e8e8"
                                  }}>
                                  <div 
                                    className='innerCard'
                                    style={{
                                    display: "flex",
                                    listStyle: "none",
                                    padding: 0,
                                    alignItems: "center"
                                  }}>
                                    <div> 
                                      <img 
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                          objectFit: "scale-down"
                                        }}
                                        alt="imagen" 
                                        src={p.picture} />
                                    </div>
                                    <div style={{ width: "100%", paddingRight: "5px", paddingLeft: "5px" }}>
                                      <ul style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        listStyle: "none",
                                        padding: 0,
                                        paddingTop: "15px"
                                      }}>
                                        <li>
                                          <ul className='innerCardTracking' style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            listStyle: "none",
                                            padding: 0
                                          }}>
                                            <li>
                                              <h3>{p.product_name}</h3>
                                            </li>
                                            <li>
                                              <span> 
                                                <b>Tracking:</b> {String(p.tracking_number).toUpperCase()}
                                              </span>
                                              {
                                                (p.payment === 1) ? <>
                                                  <i className="fas fa-exclamation-triangle zoom-in-zoom-out" />
                                                </> : <></>
                                              }
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <span> <b>Estatus:</b> {p.status}</span>
                                        </li>
                                        <li>
                                          <span> <b>Fecha:</b> {dayjs(p.date).format("YYYY-MM-DD")}</span>
                                        </li>
                                        <li>
                                          <span> <b>Pago:</b> ${Number(p.price).toFixed(2)}</span>
                                        </li>
                                        <li>
                                          <span> <b>Dirección de entrega: </b> {p.address} </span>
                                        </li>
                                      </ul>
    
                                      <div 
                                      className='btnCardGroup'
                                      style={{ 
                                        width: "100%",
                                        textAlign: "end",
                                        paddingRight: "15px" 
                                        }}>
                                        <ul className='btnCardULDetail' style={{
                                          display: "inline-flex",
                                          padding: 0,
                                          listStyle: "none"
                                        }}>
                                          <li className='btnCardBtnDetail'>
                                            <Button
                                              style={{
                                                backgroundColor: getColor(),
                                                borderColor: getColor(),
                                                color: "#fff"
                                              }}
                                              size='sm'
                                              tag={Link}
                                              to={`/admin/tracking/${p.tracking_number}`}
                                            >Ver detalles</Button>
                                          </li>
                                          <li className='btnCardLIDetail' style={{
                                            marginLeft: "10px"
                                          }}>
                                            {
                                              (p.status_id < 2) ? <>
                                              {
                                                (loading.btnDownload) ? <>
                                                  <Spinner size="sm" style={{color:getColor(), marginRight: "7px"}} />
                                                </> : <> 
                                                  <Button 
                                                    size='sm'
                                                    style={{
                                                      backgroundColor: getColor(),
                                                      borderColor: getColor(),
                                                      color: "#fff"
                                                    }}
                                                    onClick={() => downloadLabel(p.id, p.tracking_number)}
                                                  > Descargar etiqueta </Button>
                                                </> 
                                              }
                                                {
                                                  (p.pickup === 1) ? <>
                                                  {
                                                    (loading.btnCancel) ? <>
                                                      <Spinner size="sm" style={{color:getColor()}} />
                                                    </> : <>
                                                      <Button 
                                                        size='sm'
                                                        style={{
                                                          backgroundColor: "#351C15",
                                                          borderColor: "#351C15",
                                                          color: "#FFB500"
                                                        }}
                                                      onClick={() => cancelPickUp(p.id)}
                                                      >Cancelar Recogida</Button>
                                                    </>
                                                  }
                                                  </> : <>
                                                    <Button 
                                                      size='sm'
                                                      tag={Link}
                                                      style={{
                                                        backgroundColor: "#351C15",
                                                        borderColor: "#351C15",
                                                        color: "#FFB500"
                                                      }}
                                                      to={`/admin/pickup/${p.id}`}
                                                    >Programar Recogida</Button>
                                                  </>
                                                }
                                              </> : <></>
                                            }
                                          </li>
                                        </ul>
                                      </div>
    
                                    </div>
                                  </div>
    
                                </Card>
                              ))
                            }
                          </>
                        }
                      </div>
                    </>
                    }
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

