/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Spinner, Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AuthHeader from "components/Headers/AuthHeader.js";
import NotificationAlert from "react-notification-alert";
import { registerNewUser } from "./../../api/auth";
import { getColor } from "utils/token";

export default function Register() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rPassword, setRPassword] = useState('');
  const [locker, setLocker] = useState('');
  const [customize, setCustomize] = useState({logo: "", color: "", slogan: ""});
  const [code, setCode] = useState('');

  useEffect(() => {
    const customizeData = JSON.parse(localStorage.getItem("agency"));
    const params = new URLSearchParams(window.location.search);
    const codeParam = params.get('code');
    
    if (customizeData) {
      setCustomize({
        logo: customizeData.logo,
        color: customizeData.color,
        slogan: customizeData.slogan,
        id: customizeData.id
      })
    }
    
    if (codeParam) {
      setCode(codeParam);
      setLocker(codeParam);
    }
    else {
      setCode('');
    }
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const sendData = async () => {
    setLoading(true);
    
    if(String(name).trim() === "") {
      notifyAlert('danger', "Mensaje", "El nombre es requerido");
    }
    else if(String(phone).trim() === "") {
      notifyAlert('danger', "Mensaje", "El telefono es requerido");
    }
    else if(String(email).trim() === "") {
      notifyAlert('danger', "Mensaje", "El email es requerido");
    }
    else if(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email) === false ) {
      notifyAlert('danger', "Message", "The email is invalid");
    }
    else if(String(password).trim() === "") {
      notifyAlert('danger', "Mensaje", "La contraseña es requerida");
    }
    else if(String(rPassword).trim() === "") {
      notifyAlert('danger', "Mensaje", "La confirmación de contraseña es requerida");
    }
    else if(password !== rPassword) {
      notifyAlert('danger', "Mensaje", "Las contraseñas no coinciden");
    }
    else {
      var jsn = {
        "name": name,
        "phone": String(phone).trim().replace(/[\(\)\-.]/g, ''),
        "email": email,
        "password": password,
        "locker": locker
      }

      const res = await registerNewUser(jsn);
      
      if(res.success === true) {
        notifyAlert('success', "Message", res.data);

        setTimeout(() => {
          window.location.href = '/auth/login';
        }
        , 5000);
      }
      else {
        notifyAlert('danger', "Message", res.message);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <AuthHeader 
        color={customize.color}
        logo={customize.logo}
        slogan={customize.slogan}/>

      <Container className="pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="border-0 mb-0" style={{boxShadow: '0 0 0 0'}}>
              <CardBody>
                <Form role="form">
                  <FormGroup>
                    <div style={{
                      fontSize: '13px',
                      color: '#8898aa',
                      textAlign: 'center',
                      marginBottom: '1rem'
                    }}>
                      <span>Registrate con nosotros</span>
                    </div>

                    <InputGroup className="input-group-merge input-group" 
                      style={{
                        marginBottom: '1rem'
                      }}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Nombre completo"
                        type="text"
                        value={name}
                        onChange={(val) => setName(val.target.value)}
                      />
                    </InputGroup>

                    <InputGroup className="input-group-merge input-group"
                      style={{
                        marginBottom: '1rem'
                      }}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-phone-alt" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Telefono"
                        type="telefono"
                        value={phone}
                        onChange={(val) => setPhone(val.target.value)}
                      />
                    </InputGroup>

                    <InputGroup className="input-group-merge input-group"
                      style={{
                        marginBottom: '1rem'
                      }}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(val) => setEmail(val.target.value)}
                      />
                    </InputGroup>

                    <InputGroup className="input-group-merge input-group"
                      style={{
                        marginBottom: '1rem'
                      }}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Contraseña"
                        type="password"
                        value={password}
                        onChange={(val) => setPassword(val.target.value)}
                      />
                    </InputGroup>

                    <InputGroup className="input-group-merge input-group"
                      style={{
                        marginBottom: '1rem'
                      }}>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Repita la contraseña"
                        type="password"
                        value={rPassword}
                        onChange={(val) => setRPassword(val.target.value)}
                      />
                    </InputGroup>

                    <InputGroup className="input-group-merge input-group">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-box-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        disabled={(code === '') ? false : true}
                        placeholder="Codigo de referido"
                        type="text"
                        value={locker}
                        onChange={(val) => setLocker(val.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    {
                      (!loading) ? <>
                        <Button 
                          type="button"
                          style={{
                            width: '100%',
                            color: '#fff',
                            backgroundColor: getColor(),
                            border: getColor()
                          }}
                          onClick={() => sendData()}
                        > Registrarse </Button>
                      </>
                      :
                      <>
                        <Spinner 
                          style={{
                            color: getColor(),
                          }} /> 
                      </>
                    }
                  </div>
                  <div style={{
                    marginTop: '15px',
                    float: 'right',
                    fontSize: '13px'
                  }}>
                    <Link 
                      to='/auth/login'>Regresar</Link>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
